import React, { useState, useEffect } from 'react';
import { Document, Page,pdfjs  } from 'react-pdf';
import { StyleSheet } from '@react-pdf/renderer';
import Fab from '@mui/material/Fab';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
//PDFjs worker from an external cdn
// const url = 
// "https://walldent-static.s3.ap-south-1.amazonaws.com/Library/Tutorial/ORAL+MEDICINE+AND+RADIOLOGY/TOPICS/BENIGN+AND+MALIGNANT+TUMORS/NOTES/oral+cancer.pdf"
  
export default function Test(props) {

  pdfjs.GlobalWorkerOptions.workerSrc = 
  `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  pdfjs.GlobalWorkerOptions.workerSrc =  
  `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; 

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [url, setUrl] = useState("");
      
  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    setUrl(props['url'])
  },[props]);
  
  const styles = StyleSheet.create({
    page: {
      marginTop: '45% !important',
      // '@media min-width: 400': {
      //   marginTop: '45% !important',
      // },
      // '@media orientation: landscape': {
      //   marginTop: '45% !important',
      // },
    }
  });

  /*To Prevent right click on screen*/
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });
    
  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  
  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }
  
  function previousPage() {
    changePage(-1);
  }
  
  function nextPage() {
    console.log(1)
    changePage(1);
  }
  
  return (
    <div className="main">
      <Document
        file={url}
        onLoadSuccess={(e) => onDocumentLoadSuccess(e)}
        // renderMode="svg"
      >
        <Page pageNumber={pageNumber} style={styles.page}/>
      </Document>
      <div>
        <div className="pagec" style={{textAlign:'center'}}>
          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </div>
        <div className="">
              <Fab  disabled={pageNumber <= 1}
          onClick={() => previousPage()}
          style={{'top': '50%',right:'88%',
          'position': 'absolute',opacity: '0.6'}}
          size="medium" aria-label="add">
            
        <ArrowBackIosNewIcon />
      </Fab>
        <Fab disabled={pageNumber >= numPages}
          onClick={() => nextPage()}
          style={{'top': '50%',left:'88%',
          'position': 'absolute',opacity: '0.6'}}
          size="medium" aria-label="add">
            
        <ArrowForwardIosIcon />
      </Fab>

        </div>
      </div>
      </div>
  );
}