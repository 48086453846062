import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import { Row, Tabs } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { bijBolPost } from 'services/api';
import ModalPdfViewer from 'components/ModalPdfViewer';
import ReactPaginate from 'react-paginate';

const TutorialsTests = (props) => {
  const [pdfUrl, setPdfUrl] = useState("");

  const [activeKey, setActiveKey] = useState('Topics')
  const [testDetails, setTestDetails] = useState({
    tutorials: [],
    topics: [],
    questionBank: [],
    presentations: [],
    quickReads: [],
    subjectId: ''
  });
  // const [currentTopic, setCurrentTopic] = useState({});
  const [questionBank, setQuestionBank] = useState([]);
  const [mnemonics, setMnemonics] = useState([]);
  // const [minuteReads, setMinuteReads] = useState([]);
  const [open, setOpen] = useState(false);

  const [page, setPage] = useState([]);
  const [pages, setPages] = useState([]);

  const [totalsCount, setTotalsCount] = useState({
    topicsCount: 0,
    quickReadsCount: 0,
    presentationsCount: 0,
    questionBankCount: 0
  });

  useEffect(() => {

    getCounts();

    



  }, [props.match.params.TutorialsTestsid])


const getTopics=(pageIndex)=>{

  console.warn('current Page'+ page)
  const datas = { 'subjectId': props.match.params.TutorialsTestsid, onlyInfo: true, skip: pageIndex*10, limit: 10 }

    //change to get
    bijBolPost('getSubjectByIdTopics', datas).then(res => {
      console.log('res.data.data-----------', res.data.data)
      const subjectInfo = res.data.data.subjectInfo
      // console.log(res)
      setTestDetails({
        tutorials: subjectInfo,
        topics: res.data.data,
        subjectId: datas.subjectId,
        questionBank: [],
        presentations: [],
        quickReads: []
      })
      if(res.data.data.length !=0){
        selectedTopic(res.data.data[0])
      }
      
    })
      .catch((err) => {
        console.log(err)
      })

}


  const getCounts = () => {
    const counts = { "subjectId": props.match.params.TutorialsTestsid }
    bijBolPost('getSubjectAllTopicCount', counts).then(res => {
      console.warn('res.data.data---questionBank--------', res.data.data);


      setPages(Math.ceil(res.data.data.topicsCount/10));
    setPage(0)

      setTotalsCount({
        topicsCount: res.data.data.topicsCount,
        quickReadsCount: res.data.data.quickReadsCount,
        presentationsCount: res.data.data.presentationsCount,
        questionBankCount: res.data.data.questionBankCount
      })



      console.warn('Topics Count'+pages);
    

    getTopics(0);
    questionBankData();
    mnemonicsData();
    minuteReadsData();


    })
      .catch((err) => {
        console.log(err)
      })
  }


  const questionBankData = (pageIndex) => {
    const questionBankReq = { "subjectId": props.match.params.TutorialsTestsid, "skip": pageIndex*10, "limit": 10, "subtopicType": "Level-2" }
    bijBolPost('getSubjectSubTopics', questionBankReq).then(res => {
      console.log('res.data.data---questionBank--------', res.data.data);
      setQuestionBank(res.data.data)
    })
      .catch((err) => {
        console.log(err)
      })
  }

  const mnemonicsData = (pageIndex) => {
    const mnemonicsReq = { "subjectId": props.match.params.TutorialsTestsid, "skip":pageIndex*10, "limit": 10, "subtopicType": "Level-3" }
    bijBolPost('getSubjectSubTopics', mnemonicsReq).then(res => {
      console.log('res.data.data---mnemonics--------', res.data.data);
      setMnemonics(res.data.data)
    })
      .catch((err) => {
        console.log(err)
      })
  }

  const minuteReadsData = (pageIndex) => {
    const mnemonicsReq = { "subjectId": props.match.params.TutorialsTestsid, "skip": pageIndex*10, "limit": 10, "subtopicType": "quickReads" }
    bijBolPost('getSubjectSubTopics', mnemonicsReq).then(res => {
      console.log('res.data.data---quickReads--------', res.data.data);
      // setMinuteReads(res.data.data)
    })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleSelect = (id) => {
    console.log("Convtered  ---------------", id)
  }

  const handleClickOpen = (url) => {
    setPdfUrl(url)
    setOpen(true);
  };
  
  const selectedTopic = () => {
    // setCurrentTopic(topic)
  }
  const handlePageClick = (event) => {
    let pageIndex = event.selected;

    let tabName = activeKey;
    if(tabName === "Level-1"){
      setPages(Math.ceil(totalsCount.topicsCount/10));
      getTopics(pageIndex);
    }
    if(tabName === "Level-2"){
      setPages(Math.ceil(totalsCount.questionBankCount/10));

      questionBankData(pageIndex);
     
    }
    if(tabName === "Level-3"){
      setPages(Math.ceil(totalsCount.presentationsCount/10));

      mnemonicsData(pageIndex);
    }

    if(tabName === "2MinsReads"){
      setPages(Math.ceil(totalsCount.quickReadsCount/10));

      minuteReadsData(pageIndex);
    }


}

  const handlerTab = (key) => {
    console.log(key)

   

    setActiveKey(key)
    
    console.log(key)
    let tabName = key;

    console.log("activeKey",tabName)

    

    console.warn()
    console.warn(totalsCount.quickReadsCount)


    if(tabName === "Level-1"){
      setPages(Math.ceil(totalsCount.topicsCount/10));

      getTopics(0);

    }
    if(tabName === "Level-2"){
      setPages(Math.ceil(totalsCount.questionBankCount/10));

      questionBankData(0);
     
    }
    if(tabName === "Level-3"){
      setPages(Math.ceil(totalsCount.presentationsCount/10));

      mnemonicsData(0);
    }

    if(tabName === "2MinsReads"){
      setPages(Math.ceil(totalsCount.quickReadsCount/10));

      minuteReadsData(0);
    }



    

   
  }

  return (
    <div>
      <div className="col-lg-12 p-0  m-3">
        <h5 className="font-weight-bold"> </h5>
        <p className="mt-lg-4 mt-3">
        </p>
      </div>
      <Row className="mt-lg-5 mb-lg-5 mt-5 mx-lg-3 mx-0 subjects">
        <div className="col-lg-12 col-12">
          <Tabs fill={true} justify={true}
            defaultActiveKey="Level-1"
            transition={false}
            onSelect={(key) => handlerTab(key)}
            id="noanim-tab-example"
            className="mb-3">
            <Tab eventKey="Level-1" title="Level-1" type="button" onClick={() => handleSelect('topic')}>
              <div className="mt-4 mx-lg-0">
                <Row className="mt-lg-4 mt-4">
                  {testDetails['topics'].map((topic, idx) => (
                    <div key={idx} className="border-0 mt-lg-4 col-6 col-lg-2">
                      <div className="shadow card border-0 mb-4">
                        <div>
                          <div className="row">
                            <div className="col-12 text-center">
                              <Link to={`/TutorialsTopics/${topic._id}/${topic['subjectId']['_id']}`}>
                                <img src={topic.image} alt=""
                                  className="img-topics" style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                                />
                              </Link>
                            </div>
                          </div>
                          <div className="bg-white">
                            <div className="row mt-lg-4 pb-lg-3 pb-3 mt-4">
                              <div className="text-center col-12">
                                <h6
                                  className="text-dark small"
                                  style={{ fontWeight: '500' }}
                                >
                                  {topic.name}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Row>
              </div>
            </Tab>
            
            <Tab eventKey={'QuestionBank'} value='QuestionBank' title="Level-2" onClick={() => handleSelect('yesat')}>
              <div>
                <Row className="mt-lg-4 mt-4 quickReads mx-0">
                  {questionBank.map((questionBanks, idx) => (
                    <div key={idx} className="border-0 mt-lg-4 col-12 col-lg-6 pl-lg-1 pr-lg-1 shadow pl-0 pr-0" onClick={() => handleClickOpen(questionBanks['key'])}>
                      <div className="border-0">
                        <div className="card-border-radius">
                          <div className="row mx-0 align-items-center pt-2 pb-2 border-bottom-dashed">
                            <div className="col-2 col-lg-3 text-center">
                              <img src="/images/pdf.svg" className="img-fluid" alt=''/>
                            </div>
                            <div className="col-10 col-lg-9 text-left">
                              <h6
                                className="text-dark small"
                                style={{ fontWeight: '500' }}
                              >
                                {questionBanks.name}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Row>
              </div>
            </Tab>
            <Tab eventKey="Level-3" value='Presentations' title="Level-3" type="button" onClick={() => handleSelect('Presentations')}>
              <div>
                <div>
                  <Row className="mt-lg-4 mt-4 quickReads mx-0">
                    {mnemonics.map((presentation, idx) => (
                      <div key={idx} className="border-0 mt-lg-4 col-12 col-lg-6 pl-lg-1 pr-lg-1 shadow pl-0 pr-0" onClick={() => handleClickOpen(presentation['key'])}>
                        <div className="">
                          <div className="border-0">
                            <div className="card-border-radius">
                              <div className="row mx-0 align-items-center pt-2 pb-2 border-bottom-dashed">
                                <div className="col-2 col-lg-3 text-center">
                                  <img src="/images/pdf.svg" className="img-fluid" alt=''/>
                                </div>
                                <div className="col-10 col-lg-9 text-left">
                                  <h6
                                    className="text-dark small"
                                    style={{ fontWeight: '500' }}>
                                    {presentation.name}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Row>
                </div>
              </div>
            </Tab>
            {/* <Tab eventKey="2MinsReads" value='2MinsReads' title="2Mins Reads" type="button" onClick={() => handleSelect('2MinsReads')}>
              <Row className="mt-lg-4 mt-4 quickReads mx-0">
                {minuteReads.map((quickRead, idx) => (
                  <div key={idx}  className="border-0 mt-lg-4 col-12 col-lg-6 pl-lg-1 pr-lg-1 shadow pl-0 pr-0" onClick={() => handleClickOpen(quickRead['key'])}>
                    <div className="">
                      <div className="border-0">
                        <div className="card-border-radius">
                          <div className="row mx-0 align-items-center pt-2 pb-2 border-bottom-dashed">
                            <div className="col-2 col-lg-3 text-center">
                              <img src="/images/pdf.svg" className="img-fluid" alt=''/>
                            </div>
                            <div className="col-10 col-lg-9 text-left">
                              <h6
                                className="text-dark small"
                                style={{ fontWeight: '500' }}
                              >
                                {quickRead.name}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Row>
            </Tab> */}
            {/* <Tab eventKey="Notes" value='Notes' title="Notes" type="button" onClick={() => handleSelect('Notes')}>
              {notes.map(note =>
                <div onClick={() => handleClickOpen(note['key'])}>
                  <div class="row mb-1 justify-content-center">
                    <div class="col-3 text-center"><i class="fa fa-file-text-o" aria-hidden="true"></i></div>
                    <div class="col-9">{note['name']}</div>
                  </div>
                  <hr class="m-2 dotted-hr" />
                </div>
              )}
              <Dialog
                fullScreen={fullScreen}
                open={open}
                style={{ minWidth: '80%' }}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
              >
                <Fab
                  style={{
                    'top': '1%', left: '2%', 'z-index' : '1',
                    'position': 'absolute'
                  }}
                  size="medium" onClick={() => handleClose()}>

                  <CloseSharp />
                </Fab>
                <Pdfviewer url={pdfUrl} />
              </Dialog>
            </Tab> */}
            {/* <Tab eventKey="Completed" title="Completed" type="button">
              <InfiniteScroll
                dataLength={completedtests.length}
                next={fetchMoreData}
                hasMore={hasMoreCompleted}
                refreshFunction={k => console.log(k)}
                pullDownToRefresh={true}
                loader={<div class="m-3"><Loading /></div>}
              >
                <TestCard tests={completedtests} />
              </InfiniteScroll>
              </Tab> */}
          </Tabs>

         
{
          
          pages>0 &&
                  <div className="fixed-bottom "> 
                      <ReactPaginate
                          previousLabel={'<<'}
                          nextLabel={'>>'}
                          pageCount={pages}
                          onPageChange={handlePageClick}
                          containerClassName={'pagination'}
                          activeClassName={'active'}
                      />
                  </div>

}
        </div>
        {/* <Dialog
          fullScreen={fullScreen}
          open={open}
          style={{ minWidth: '80%' }}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title">
          <Fab
            style={{
              'top': '1%', left: '2%', 'z-index' : '1',
              'position': 'absolute'
            }}
            size="medium" onClick={() => handleClose()}>
            <CloseSharp />
          </Fab>
          <Pdfviewer url={pdfUrl} />
        </Dialog> */}
         {/* {Modal} */}
         <ModalPdfViewer open={open} onCloseModal={(e) => setOpen(e)} pdfUrl={pdfUrl}/>
      </Row>
    </div>
  )
}

export default TutorialsTests;
