import { tokenKeyName } from 'assets/data/constants';
import { useDispatch } from 'react-redux'

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

//If Access Token Exist then set user details to redux
export const setUserWithToken = () => {
    const dispatch = useDispatch();
    const access_token = parseJwt(getCookie(tokenKeyName));
    if (access_token && access_token['user']) {
        console.log('accessToken', access_token)
        dispatch({ type: "SET_PROFILE", value: access_token.user });
        return access_token.user;
    }
    return {};
}

export const setCookieToken = (token) => {
    const dispatch = useDispatch();
    const access_token = parseJwt(token);
    if (access_token && access_token['user']) {
        console.log('accessToken', access_token)

        document.cookie = `${tokenKeyName}=${token};Secure; SameSite=None; Domain=${window.location.hostname.replace(/^([^.]+)/,'')}`;
        dispatch({ type: "SET_PROFILE", value: access_token.user });
    }
    return;
}
