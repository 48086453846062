import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.scss'
import { Link } from 'react-router-dom'
import { bijBolPost } from '../services/api';
import Loading from 'components/Loading';

const Livetests = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tests, setTests] = useState([]);

  useEffect(() => {
    // before fetching data
    setIsLoading(true);
    if(props.data === "" || props.data){
      setTests(props.data);
      setIsLoading(false);
      return
    }
    let datas = { skip: 0, limit: 10 };

    bijBolPost('getLiveTests', datas).then(res => {
      console.log("getLiveTests inside Livetests after props change=>", res['data']['data']);
      setTests(res['data']['data']);
      console.log('props livetest', props)
      if(props['onDataFetch']) props['onDataFetch'](res['data']['data']);
      // when data is fetching
      setIsLoading(false);
    }).catch(e => {
      console.log("Error in Vision MDS => ", e);
    })
  }, [props.data]);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="mt-lg-3 mx-lg-0 mt-4 mx-0">
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            navigation
            autoplay={true}
            breakpoints={{
              320: {
                width: 350,
                slidesPerView: 1,
              },
              768: {
                width: 768,
                slidesPerView: 2,
              },
              1024: {
                width: 1024,
                slidesPerView: 2,
              }
            }}
          >
            {tests.map((test, index) => (
              <SwiperSlide key={index}>
                <div className="pl-0 pr-0 pl-lg-4">
                  <div
                    className="card border-0 shadow mb-4"
                    key={index}
                    style={{ height: '291px' }}
                  >
                    <div className="bg-dark p-lg-4 p-4 card-border-radius">
                      <div className="row">
                        <div className="col-12 text-left">
                          <h6 className="text-white">{test.name}</h6>
                        </div>
                      </div>
                      <div className="row align-items-center">
                        <div className="col-lg-8 col-8  text-left">
                          <small className="text-white font-weight-light">
                            Date: {test.scheduleDate}
                          </small>
                        </div>
                        <div className="col-lg-4 text-right col-4">
                          <small className="text-danger">Active</small>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white">
                      <div className="row mt-lg-4 mt-4 mx-0 pl-lg-4">
                        <div className="col-lg-6 col-6 pl-5 pl-lg-1 text-left">
                          <ul>
                            <li>1 Synopsis</li>
                            <li className="mt-lg-3">1 Videos</li>
                          </ul>
                        </div>
                        <div className="col-lg-6 col-6 text-left">
                          <ul>
                            <li>{test.totalQuestionsCount} Questions</li>
                            <li className="mt-lg-3">{test.duration} Minutes</li>
                          </ul>
                        </div>
                      </div>
                      <div className="row mt-lg-3 mt-3 mb-3 mx-0 mb-lg-3 align-items-center">
                        <div className="col-lg-6 col-6">
                          <a href sstyle={{ color: '#0555E2' }}>
                            <u style={{ color: '#0555E2' }} className=" text-nowrap">How to prepare?</u>
                          </a>
                        </div>
                        <div className="col-lg-6 text-right col-6">
                            <Link to={test['userTestStatus'] === 'resumeTest' ? `/Questions/${test._id}` : `/testInfo/${test._id}`}>
                              <button
                                type="button"
                                className="btn btn-danger border-0"
                                style={{ background: '#0555E2' }}
                              >
                                {{
                                  'retakeTest': 'Retake Test',
                                  'resumeTest': 'Resume Test',
                                  'takeTest': 'Take Test'
                                }[test['userTestStatus']]
                                }
                              </button>
                            </Link>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  )
}

export default Livetests
