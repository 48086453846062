/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Row, Tabs } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import { bijBolPost } from 'services/api';
import Dialog from '@mui/material/Dialog';
import Loading from 'components/Loading';
import TestCard from 'components/TestCard';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import pdfEyeImg from 'assets/images/pdf-eye.png';
import ModalPdfViewer from 'components/ModalPdfViewer';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CustomVideo from 'components/CustomVideo';
import ReactPaginate from 'react-paginate';

const TutorialsTopics = (props) => {

  // eslint-disable-next-line no-unused-vars
  const [tutorialTopicDetails, setTutorialTopicDetails] = useState({
    videos: [],
    notes: [],
    topicTests: [],
    topicInfo: {}
  });
  const [activeKey, setActiveKey] = useState('Videos')
  const [skipLimitTest, setSkipLimitTest] = useState({ "skip": 0, "limit": 10 });
  const [skipLimitVideo, setSkipLimitVideo] = useState({ "skip": 0, "limit": 10 });
  
  const [notes, setNotes] = useState([]);
  const [videos, setVideos] = useState([]);
  const [tests, setTests] = useState([]);
  const [topicCounts, setTopicCounts] = useState({});
 
  const [currentVideo, setCurrentVideo] = useState({});
  const [open, setOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [isOpenVid, setIsOpenVid] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [page, setPage] = useState([]);
  const [pages, setPages] = useState([]);
  

  const [isLoading, setIsLoading] = useState({
    "loadingVideos": true,
    "loadingNotes": true, "loadingTests": true
  });


  
  const subTopicCounts = () => {
    const req = { "topicId": props.match.params.topicId }

    bijBolPost('getTopicSubtopicCount', req).then(res => {
      
    let timer = setTimeout(() => {
      console.log('This will run after 1 second!')
      let testinfo = res.data.data;

      console.log('counts', '---', testinfo)

      setTopicCounts(testinfo);
      setPages(Math.ceil(testinfo.videosCount/10));

      console.log(testinfo)

      console.log(topicCounts)
    }, 600);
    console.warn(timer)

      let subjectBySubDetailsReq = { "subjectId": props.match.params.subjectId, "onlyInfo": true, "skip": 0, "limit": 10 }
    bijBolPost('getSubjectByIdTopics', subjectBySubDetailsReq).then(res => {

      console.log('getSubjectByIdTopics', res.data.data)


      console.log('latest'+ topicCounts)

      notesData();
      testsData();
      videosData();

    })
      .catch((err) => {
        console.log(err)
      })
      
     


    })
      .catch((err) => {
        console.log(err)
      })
  }

  const handlerTab = (key) => {
    console.log(key)

    let timer = setTimeout(() => {
      console.log('This will run after 1 second!')
    }, 600);

    console.warn(timer)
    setActiveKey(key)


    setPage(0)

    let tabName = key;

    let skipLimit = { "skip": page*10, "limit": 10 }
    console.log("activeKey", activeKey)
    if (tabName === "Tests") {

      
       
      setPages(Math.ceil(topicCounts.testsCount/10));
      setSkipLimitTest(skipLimit)
      testsData(skipLimit);
    }
    if (tabName === "Videos") {
      console.log("activeKey Upcoming", activeKey)
      
      setPages(Math.ceil(topicCounts.videosCount/10));
      setSkipLimitVideo(skipLimit)
      videosData(skipLimit)
    }
    if (tabName === "Notes") {
      console.log("activeKey Upcoming", activeKey)
      
      setPages(Math.ceil(topicCounts.notesCount/10));
      notesData(skipLimit)
    }





  }

  const handleClickOpen = (url) => {
    setPdfUrl(url)
    setOpen(true);
  };

  const handleSelect = (id) => {
    console.log("Convtered  ---------------", id)
  }

  useEffect(() => {
    subTopicCounts();
  

    
  }, [props])

  const videosData = (skipLimit = skipLimitVideo) => {
    let videoReq = {
      ...skipLimit, "topicId": props.match.params.topicId,
      "subtopicType": 'videos'
    }

    //this.props.match.params.testId,
    console.log('this.props.match.params -----------------------------------------------------------', props.match.params)
    bijBolPost('getTopicBySubtopicDetails', videoReq).then(res => {

      setPage(0);

      //setPages(topicCounts.videosCount/10);
      console.warn(page);
      let testinfo = res.data.data;
      console.log('videos', '---', res.data.data)
      if (testinfo && testinfo.length > 0) {
        setCurrentVideo(testinfo[0])
        setVideos(testinfo);
      } else {
        console.log('hasMoreFalse')
        setVideos(videos);
        //setHasMoreVideos(false);
      }
      setIsLoading({ loadingVideos: false })
    })
      .catch((err) => {
        console.log(err)
      })
  }

  const notesData = (skipLimit = skipLimitVideo) => {
    let noteReq = {
      ...skipLimit, "topicId": props.match.params.topicId,
      "subtopicType": 'notes'
    }
    //this.props.match.params.testId,
    console.log('this.props.match.params -----------------------------------------------------------', props.match.params)
    bijBolPost('getTopicBySubtopicDetails', noteReq).then(res => {
      let testinfo = res.data.data;
      console.log('notes', '---', res.data.data)
      if (testinfo && testinfo.length > 0) {
        setNotes(testinfo);
      } else {
        console.log('hasMoreFalse')
        setNotes(notes);
        //setHasMoreNotes(false)
        // setTests(tests); 
      }
      setIsLoading({ loadingNotes: false })
    })
      .catch((err) => {
        console.log(err)
      })
  }


  const testsData = (skipLimit = skipLimitTest) => {
    console.log(skipLimit)
    let testReq = {
      ...skipLimit, "topicId": props.match.params.topicId,
      "subtopicType": 'tests'
    }
    console.log('this.props.match.params -----------------------------------------------------------', props.match.params)
    bijBolPost('getTopicBySubtopicDetails', testReq).then(res => {
      let testinfo = res.data.data;
      console.log('tests', '---', res.data);
      if (testinfo && testinfo.length > 0) {
        setTests(testinfo);
      } else {
        console.log('hasMoreFalse')
        setTests(tests);
        //setHasMoreTests(false)
        // setTests(tests); 
      }
      setIsLoading({ loadingTests: false })
    })
      .catch((err) => {
        console.log(err)
      })
  }

  const checkForPremium = (isPremium) => {
    if (isPremium) {
      props.history.push(`/Subscription/${props.match.params.subjectId}/subject?backUrl=${window.location.pathname}`);
      return false;
    }

    return true;
  }

  const handlePageClick = (event) => {
    let page = event.selected;
    setPage(page)

    let skipLimit = { "skip": page*10, "limit": 10 }
    console.log("activeKey", activeKey)
    if (activeKey === "Tests") {
      setSkipLimitTest(skipLimit)
      testsData(skipLimit);
    }
    if (activeKey === "Videos") {
      console.log("activeKey", activeKey)
      setSkipLimitVideo(skipLimit)
      videosData(skipLimit)
    }
    if (activeKey === "Notes") {
      console.log("activeKey", activeKey)
      setSkipLimitVideo(skipLimit)
      notesData(skipLimit)
    }

    
}

  return (
    <div>
      <Row className="mx-0 mx-lg-4 mt-lg-5 mt-5">
        <div className="col-lg-12">
          <h5 className="font-weight-bold"> {tutorialTopicDetails['topicInfo.name']}</h5>
          <p className="mt-lg-4 mt-3">
            {currentVideo['name']}
          </p>
          <Row className="mx-0 mt-lg-4">
            <div className="col-lg-3 col-6">
              <ul>
                <li>{topicCounts['videosCount']} Videos</li>
                <li className="mt-3">{topicCounts['notesCount']} Notes</li>
              </ul>
            </div>
            <div className="col-lg-3 col-6">
              <ul>
                <li className="mt-3">{topicCounts['testsCount']} Tests</li>
              </ul>
            </div>
          </Row>
        </div>
        <div className="col-lg-12 Tutorials-Topics mt-lg-5 mt-3 col-12">
          <Tabs
            fill={isMobile ? true : false} justify={isMobile ? true : false}
            defaultActiveKey="Videos"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
            onSelect={(key) => handlerTab(key)}>
            <Tab eventKey="Videos" value='Videos' title="Videos">
              {isLoading['loadingVideos'] ?
                <div className={"m-3"}><Loading /></div>
                :
                (activeKey === 'Videos' && 
                
                <Row>{videos.map((vds, idx) => (
                  <div key={idx} className="col-6 col-lg-3 mt-3">
                    <div className="video-box" onClick={() => {
                      if (vds['isPremium']) return checkForPremium(vds['isPremium']);
                      setIsOpenVid(true);
                      setCurrentVideo(vds)
                    }}>
                      <img alt=""
                        src={`data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAATcAAACiCAMAAAATIHpEAAAAdVBMVEUAAABYW13///9TVlhXWlyLjY5bXmBQU1VYW15UV1lDRkdLTk8qLC0vMTIyNDU/QUMlJygcHR4VFhc7PT74+Pl2eHoMDQ0gISFMT1IREhKDhYbV1tbg4OHP0NF9f4DExcapq6uTlZaho6Tv8PC0tbZkZ2ltb3Gs7gdhAAACWElEQVR4nO3aW3eiMBSG4UIwIYCA4gGt1mnV/v+fONp2BGSD3iVd8z4XXnmx17eyyfHlBQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA/G/m63I6zdYz13X8KpuiNjbSWltVJ6Xran6JVa60Dho6KBh1j01NcE/bYuO6LM9tCt2L7ZqcoVvHzCIptSubu67NY2U8FFsQ68p1dd4qxR699Wrhuj5PDTfpT3C0qkiNxxYEEZODoGq61BqjpMFnXNfooXUTlNq+vk6MFTqVuaEnuQ23SO/CMNwvVT85M3ddpm9mrS7dhl/2b70xx4C719onqMl3buHisDTdz1wcua7TM6s06Od2Se6PukuOLX7HWou5XZJ7P7eTY/HblQ3lFoa7iW2SixPXlfolGc7tkty7vU0QzKgdo7ldplatyE3SXnAIuYWL5c8/9Np1qV6x8Xhu4YchN8HD3CaK3ASt5ZuY20f0PaXGlu9b2+i8sDi81f9WIorc2vKR3PbbqDmaS1euS/VKOZjbfpvaZsOgWfd2zI2c26491q6mriv1TCKch4THU31/7uu6Tt9k/dyOp/N9arRpT9Oo0efxGtvps+7dMXAz05M16ZjT8Xg4CxcMDLe+VWtmqJfLVLrP0pxaCloBWeEyK2AyleWjzxwuo612XaGnqvH3IYatwoBCDz9I0pxYDquGn9awMR2TKblXucd6YJMKwWnDE66HyjTqRKcDldOjz8gqpfXXW/zrbzLlMfnT5mVVpGla5TOGGgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwFP+AolzFn9kBvLYAAAAAElFTkSuQmCC`}
                        className="img-fluid"
                        style={{ borderRadius: '10px' }} />
                    </div>
                  </div>
                ))}
                </Row>
                
               
                
                )
              }
            </Tab>
            <Tab eventKey="Notes" value='Notes' title="Notes" onClick={() => handleSelect('Notes')}>
              {isLoading['loadingNotes'] ?
                <div className="m-3"><Loading /></div>
                :
                (activeKey === 'Notes' && 
                
                <Row>{notes.map((note, idx) =>
                  <div key={idx} className={isMobile ? 'col-12 col-lg-5' : "mt-lg-4 col-12 col-lg-5 pl-lg-1 pr-lg-1 shadow pl-0 pr-0"} onClick={() => checkForPremium(note['isPremium']) && handleClickOpen(note['key'])}>
                    <div className="row mb-1 justify-content-center">
                      <div className="col-2 col-lg-3 text-center">
                        <img src={pdfEyeImg} alt="pdfEyeImg" />
                      </div>
                      <div className="col-10 col-lg-9 text-left">{note['name']}</div>
                    </div>
                    {isMobile && <hr className="m-2 dotted-hr" />}
                  </div>
                )}
                </Row>
                
              )
              }
            </Tab>
            <Tab eventKey="Tests" value='Tests' title="Tests" type="button">
              {isLoading['loadingTests'] ?
                <div className="m-3"><Loading /></div>
                : (activeKey === 'Tests' && 
                
                <TestCard tests={tests} type={'subject'} />
                
               )}</Tab>
          </Tabs>

          {pages>0 &&
          <div className="fixed-bottom "> 
                      <ReactPaginate
                          previousLabel={'<<'}
                          nextLabel={'>>'}
                          pageCount={pages}
                          onPageChange={handlePageClick}
                          containerClassName={'pagination'}
                          activeClassName={'active'}
                      />
                  </div>

          }
          
        </div>
      </Row>
      <ModalPdfViewer open={open} onCloseModal={(e) => setOpen(e)} pdfUrl={pdfUrl} />

      
      {isOpenVid && <Dialog
        fullScreen={true}
        onClose={() => setIsOpenVid(false)}
        aria-labelledby="customized-dialog-title"
        open={isOpenVid}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <IconButton
            aria-label="close"
            onClick={() => setIsOpenVid(false)}
            sx={{
              position: "absolute",
              right: 8,
              zIndex: 12,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent textAlign="center" class="m-3">
          {/* <VideoSynops url={currentVideo.key} /> */}
          <CustomVideo videoInfo={{ videoLinkObj: currentVideo.key }} />
        </DialogContent>
      </Dialog>}
    </div>
  )
}

export default TutorialsTopics;