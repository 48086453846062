import React from 'react';
import { Row } from 'react-bootstrap';

const Quickaccess = () => {
    return (
        <div>
            <Row className="mx-lg-3 mt-lg-3 mt-3">
                <div className="col-lg-3 col-6">
                    <div className="py-lg-5 p-4 p-lg-0 text-center Quickaccess" style={{ background: '#ffc400', borderRadius: '10px' }}>
                        <div><h5 className="font-weight-bold">MCQ's Questions</h5></div>
                        <div><p>0/6325 Completed</p></div>
                    </div>
                </div>
                <div className="col-lg-3 col-6">
                    <div className="py-lg-5 text-center p-4 p-lg-0 Quickaccess" style={{ background: '#ffc400', borderRadius: '10px' }}>
                        <div><h5 className="font-weight-bold">NEET Crash Course Tutorials</h5></div>
                        <div><p>For All Exams</p></div>
                    </div>
                </div>
                <div className="col-lg-3 col-6 mt-3 mt-lg-0 mt-md-0">
                    <div className="py-lg-5 text-center p-4 p-lg-0 Quickaccess" style={{ background: '#ffc400', borderRadius: '10px' }}>
                        <div><h5 className="font-weight-bold">AIIMS Papers</h5></div>
                        <div><p>0/325 Completed</p></div>
                    </div>
                </div>
                <div className="col-lg-3 col-6 mt-lg-0 mt-md-0 mt-3">
                    <div className="py-lg-5 text-center p-4 p-lg-0 Quickaccess" style={{ background: '#ffc400', borderRadius: '10px' }}>
                        <div><h5 className="font-weight-bold">Orthodentics</h5></div>
                        <div><p>For All Exams</p></div>
                    </div>
                </div>
            </Row>
        </div>
    )
}

export default Quickaccess;