import React from 'react'
import { useState, useEffect } from 'react'
import { Row, Modal } from 'react-bootstrap'
import { testModalStatus } from 'assets/data/constants';

const QuestionStatusModal = (props) => {
    const [show, setShow] = useState(false)
    const [attemptedQuestion, setAttemptedQuestion] = useState(new Map())
    const [questions, setQuestions] = useState([])

    useEffect(() => {
        setAttemptedQuestion(props['attemptedQuestion'])
        setQuestions(props['questions'])
        setShow(props['show'])
        console.log("props in Modal ->", props)
    }, [props['show']]);

    const answerStatus = (data) => {
        if (data && data['selectedOptions']) return 'selected';
    
        return 'not-answered'
        // Uncomment this when you want to show the list with correct and incorrect
        
        // if (data['isCorrect'] === 1) return 'correct';
    
        // if (data['isCorrect'] === 0) return 'incorrect';
      }

    return (<div >
        <Modal
            show={show}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header>
                <div className="col-12 text-right">
                    <button
                        type="button"
                        className="btn-close border-0 pl-3 pr-3 text-white pt-2 pb-2"
                        style={{
                            background: 'rgb(249, 0, 80)',
                            borderRadius: '25px',
                        }}
                        onClick={() => {
                            console.log('onclose')
                            props.onClose(false)
                            setShow(!show)
                        }}
                    >
                        close
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body
                className="pr-lg-0 pr-0"
                style={{ height: '50vh', overflowY: 'scroll' }}>

                <div className="row align-items-center m-1 pl-lg-3 pl-1">
                    {
                        testModalStatus.map((a, key,idx) => {
                            return <div key={idx} className="row col-lg-3 col-xs-6 col-6 gx-5 m-0 p-1">
                                <div style={a['style']}
                                ></div>
                                <div clasclassNames="pl-2">{a['status']}</div>
                            </div>
                        })
                    }
                </div>
                <Row className="col-12 px-lg-2 pl-lg-3 row m-0">
                    {questions.length > 0 && questions.map((data, index) => (
                        <div key={index}
                            className={`pb-2 pl-3 pr-4 pt-2 col-1 col-lg-1 mr-lg-2 mt-lg-2 text-center 
                    p-lg-2 cursor-pointer mr-2 mt-2 ${answerStatus(attemptedQuestion.get(data['_id']))}`}
                            style={{
                                borderRadius: '10px',
                                border: '1px solid rgb(33, 13, 58)',
                            }}
                            onClick={() => {
                                props.setCurrentIndex(index)
                                // setCurrentIndex(index)
                                setShow(!show)
                            }}
                        >
                            <span className="filter-font">{index + 1}</span>
                        </div>
                    ))}
                </Row>
            </Modal.Body>
        </Modal>
    </div>)
}

export default QuestionStatusModal;