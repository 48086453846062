import { useDispatch } from 'react-redux';

const useSetExt = () => {
    const dispatch = useDispatch();

    const isExternal = new URLSearchParams(window.location.search).get('ext');
    console.log('isExternal scrolltop=> ', isExternal);
    
    dispatch({ type: "SET_EXT", value: {ext : isExternal} });
    return;
}


export {useSetExt};   