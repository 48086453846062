import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import { Row } from 'react-bootstrap'

const Recommendedvideos = () => {
    return (
      <div>
        <Row className="mx-lg-3 mt-lg-3 mt-3">
          <div className="col-lg-12">
            <Swiper
              spaceBetween={20}
              slidesPerView={1}
              navigation
              loop={true}
              autoplay={true}
              breakpoints={{
                320: {
                  width: 320,
                  slidesPerView: 1,
                },
                768: {
                  width: 768,
                  slidesPerView: 2,
                },
                1024: {
                  width: 350,
                  slidesPerView: 1,
                }
              }}
            >
              <SwiperSlide>
                <div className="video-box">
                  <a href>
                    <img alt='' src="images/teeth1.jpg" className="img-fluid" style={{borderRadius: '10px'}}/>
                    <div className="play-btn">
                      <i className="fa fa-play"></i>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className="video-box">
              <a href>
              <img alt='' src="images/teeth2.jpg" className="img-fluid" style={{borderRadius: '10px'}}/>

                    <div className="play-btn">
                      <i className="fa fa-play"></i>
                    </div>
                  </a>
                  </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className="video-box">
              <a href>
              <img alt='' src="images/teeth3.jpg" className="img-fluid" style={{borderRadius: '10px'}}/>
                    <div className="play-btn">
                      <i className="fa fa-play"></i>
                    </div>
                  </a>
                  </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className="video-box">
              <a href>
              <img alt='' src="images/teeth4.jpg" className="img-fluid" style={{borderRadius: '10px'}}/>

                    <div className="play-btn">
                      <i className="fa fa-play"></i>
                    </div>
                  </a>
                  </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className="video-box">
              <a href>
              <img alt='' src="images/teeth2.jpg" className="img-fluid" style={{borderRadius: '10px'}}/>

                    <div className="play-btn">
                      <i className="fa fa-play"></i>
                    </div>
                  </a>
                  </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className="video-box">
              <a href>
              <img alt='' src="images/teeth3.jpg" className="img-fluid" style={{borderRadius: '10px'}}/>

                    <div className="play-btn">
                      <i className="fa fa-play"></i>
                    </div>
                  </a>
                  </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className="video-box">
              <a href>
              <img alt='' src="images/teeth4.jpg" className="img-fluid" style={{borderRadius: '10px'}}/>

                    <div className="play-btn">
                      <i className="fa fa-play"></i>
                    </div>
                  </a>
                  </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </Row>
      </div>
    )
}

export default Recommendedvideos;