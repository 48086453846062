import React from 'react'
import { Row, ProgressBar } from 'react-bootstrap'

const Survey = () => {
    return (
      <div className="d-none d-lg-block d-md-block">
        <Row className="mx-lg-3 mt-lg-5 mb-lg-5 mt-5">
          <div className="col-lg-12 col-12">
            <div className="card border-0 shadow" style={{borderRadius: '10px'}}>
                <div className="ml-lg-4 mr-lg-4">
                <h4 className="mt-lg-4">Quick Survey!</h4>
                <div><ProgressBar className="col-lg-8 pl-0 mt-3" variant="danger" now={10} /></div>
                {/* 1st Question */}
                <div className="mt-lg-4"><p>What stage are you in?</p></div>
                <Row>
                    <div className="col-lg-4 col-12">
                        <p
                        style={{
                            borderRadius: '10px',
                            border: '1px solid #000',
                            padding: '10px',
                        }}
                        >
                        BDS 1st Year
                        </p>
                    </div>
                    <div className="col-lg-4 col-12">
                        <p
                        style={{
                            borderRadius: '10px',
                            border: '1px solid #000',
                            padding: '10px',
                        }}
                        >
                        BDS 2nd Year
                        </p>
                    </div>
                    <div className="col-lg-4 col-12">
                        <p
                        style={{
                            borderRadius: '10px',
                            border: '1px solid #000',
                            padding: '10px',
                        }}
                        >
                        BDS 3rd Year
                        </p>
                    </div>
                    <div className="col-lg-4 col-12 mt-lg-3">
                        <p
                        style={{
                            borderRadius: '10px',
                            border: '1px solid #000',
                            padding: '10px',
                        }}
                        >
                        BDS 4th Year
                        </p>
                    </div>
                    <div className="col-lg-4 col-12 mt-lg-3">
                        <p
                        style={{
                            borderRadius: '10px',
                            border: '1px solid #000',
                            padding: '10px',
                        }}
                        >
                        Internship
                        </p>
                    </div>
                    <div className="col-lg-4 col-12 mt-lg-3">
                        <p
                        style={{
                            borderRadius: '10px',
                            border: '1px solid #000',
                            padding: '10px',
                        }}
                        >
                        Graduated
                        </p>
                    </div>
                </Row>
                {/* 1st Question end*/}
                {/* 2nd Question */}
                {/* <div className="mt-lg-4"><p>Which MDS PG entrance exams are you targeting?</p></div>
                <Row className="mx-lg-0"><p>NEET</p></Row>
                <Row>
                    <div className="col-lg-4 col-12">
                        <p
                        style={{
                            borderRadius: '10px',
                            border: '1px solid #000',
                            padding: '10px',
                        }}
                        >
                        DEC 2021
                        </p>
                    </div>
                    <div className="col-lg-4 col-12">
                        <p
                        style={{
                            borderRadius: '10px',
                            border: '1px solid #000',
                            padding: '10px',
                        }}
                        >
                        DEC 2022
                        </p>
                    </div>
                    <div className="col-lg-4 col-12">
                        <p
                        style={{
                            borderRadius: '10px',
                            border: '1px solid #000',
                            padding: '10px',
                        }}
                        >
                        DEC 2023
                        </p>
                    </div>
                    <div className="col-lg-4 col-12 mt-lg-3">
                        <p
                        style={{
                            borderRadius: '10px',
                            border: '1px solid #000',
                            padding: '10px',
                        }}
                        >
                        DEC 2024
                        </p>
                    </div>

                    <div className="col-lg-4 col-12 mt-lg-3">
                        <p
                        style={{
                            borderRadius: '10px',
                            border: '1px solid #000',
                            padding: '10px',
                        }}
                        >
                        DEC 2021
                        </p>
                    </div>
                    <div className="col-lg-4 col-12 mt-lg-3">
                        <p
                        style={{
                            borderRadius: '10px',
                            border: '1px solid #000',
                            padding: '10px',
                        }}
                        >
                        DEC 2022
                        </p>
                    </div>
                    <div className="col-lg-4 col-12 mt-lg-3">
                        <p
                        style={{
                            borderRadius: '10px',
                            border: '1px solid #000',
                            padding: '10px',
                        }}
                        >
                        DEC 2023
                        </p>
                    </div>
                    <div className="col-lg-4 col-12 mt-lg-3">
                        <p
                        style={{
                            borderRadius: '10px',
                            border: '1px solid #000',
                            padding: '10px',
                        }}
                        >
                        MAY 2023
                        </p>
                    </div>
                </Row> */}
                {/* 2nd Question end*/}
                {/* 3rd Question */}
                {/* <div className="mt-lg-4"><p>Course Details</p></div>
                <Row>
                    <div className="col-lg-4 col-12">
                        <input type="text" className="form-control form_control" placeholder="College Name"
                        style={{
                            borderRadius: '10px',
                            border: '1px solid #000',
                            padding: '10px',
                        }}
                         />
                        
                    </div>
                    <div className="col-lg-4 col-12">
                    <input type="text" className="form-control form_control" placeholder="College Reg.No"
                        style={{
                            borderRadius: '10px',
                            border: '1px solid #000',
                            padding: '10px',
                        }}
                         />
                    </div>
                </Row> */}
                {/* 3rd Question end*/}
                <Row className="mt-lg-3 mb-lg-3">
                    <div className="col-lg-6 col-6 mt-3 mt-lg-0 text-right">
                    <button
                        className="ui inverted button btn btn-info border-0 pl-5 pr-5 text-dark" style={{background: '#f2f2f2'}}
                    >
                        Skip
                    </button>
                    </div>
                    <div className="col-lg-6 col-6 mt-3 mt-lg-0 text-left">
                    <button
                        className="ui inverted button btn btn-info border-0 pl-5 pr-5" style={{background: 'rgb(249, 0, 80)'}}
                    >
                        Next
                    </button>
                    </div>
                </Row>
                </div>
            </div>
          </div>
        </Row>
      </div>
    )
}

export default Survey;