const initialState = {
    ext: true,
  };
  
  export default function QueryList(state = initialState, action) {
    switch (action.type) {
      case "SET_EXT":
        return {
          ...state,
          ...action.value,
        };
      default:
        return state
    }
  }