import axios from 'axios';
import authHeader from './auth-header';
import * as constants from 'assets/data/constants'
import { createBrowserHistory } from 'history';

const history = createBrowserHistory({
    forceRefresh: true
    });

//Post Api for Bijbol
const bijBolPost = async (apiName , datas) => {
    try{
        // eslint-disable-next-line no-unused-vars
        const result = await axios.post(constants['baseApiUrl'] + apiName, datas, { headers: authHeader()
            , withCredentials: true 
        });
        if(result['data']['message'] === constants['errorInvalidUserMessage']){
            const params = new URLSearchParams(window.location.search);
            return history.push('/login'+ `?${params}`)
        }
        return result;
    }catch(e){
        return e;
    }
}

//Get Api
const bijBolGet = async (apiName) => {
    try{
        return await axios.get(constants['baseApiUrl'] + apiName, { headers: authHeader()
            , withCredentials: true 
        });
    }catch(e){
        return e;
    }
}

export {
    bijBolPost,
    bijBolGet
}