import { bijBolPost } from "services/api";
import logo from 'assets/images/logo.png';

export const orderSubscription = async (planInfo) => {
    try {
        console.log('planInfo',planInfo)
        const orderRes = await bijBolPost("getOrderIds", {
            planId: planInfo['id'] ? planInfo['id'] : planInfo['_id'],
            amount: planInfo['finalPrice'] * 100,
            currency: 'INR',
            receipt: `Receipt_${Math.floor(Math.random() * 10)}`,
            payment_capture: 1,
        });
        return {...orderRes['data']['data'],...{'planId': planInfo['id'] ? planInfo['id'] : planInfo['_id']}};
    } catch (err) {
        console.log(err)
        return {}
    }
};

export const loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

export const displayRazorpay = async (planInfo, userDetails) => {
    if(!userDetails) return alert('Please Login Before you Buy')

    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
    }

    // creating a new order
    const result = await orderSubscription(planInfo);

    if (!result) {
        alert("Server error. Are you online?");
        return;
    }

    // Getting the order details back
    const { amount, order_id, currency, planId } = result;
    console.log('Result => ',result)
    const options = {
        // key: 'rzp_live_vhknaVi51boKS3',//"rzp_test_3mL1ReWJFQbW2S", // Enter the Key ID generated from the Dashboard secret tCGJa3h8wCj41RbJ44nNVP0o
        key: "rzp_live_vhknaVi51boKS3", // Enter the Key ID generated from the Dashboard secret tCGJa3h8wCj41RbJ44nNVP0o
        amount: parseFloat(amount) / 100,// added * 100 because razor pay only take money in paise
        currency: currency,
        name: "Bijbol Corp.",
        description: "",
        image: { logo },
        order_id: order_id,
        prefill: {
            name: userDetails['userName'],
            email: userDetails['email'],
            contact: userDetails['phoneNum'],
        },
        handler: async function (response) {
            console.log(response)
            const data = {
                "razorpay_payment_id": response.razorpay_payment_id,
                "razorpay_signature": response.razorpay_signature,
                "razorpay_order_id": result['order_id'],
                "isCeVideo": false,
                "planId": planId,
                "subscription_type": "plan",
                "userId": userDetails['_id'],
                "price": parseFloat(amount) / 100,
                "paymentStatus": "completed"
            }
            const verifySigRes = await bijBolPost('verifySignatures', data);
            console.log('Verify Signature => ',verifySigRes)
            const params = new URLSearchParams(window.location.search);
            window.location.href = window.location.search ? window.location.search.replace('?backUrl=', '' ) : 'home'+ `?${params}`
            return;
            // alert(verifySigRe);
        },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
}
