/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Row } from 'react-grid-system'
import { Link } from 'react-router-dom'
import { bijBolPost } from 'services/api';


const VisionmdsTests = (props) => {  

  const [tests, setTests] = useState([]);

  useEffect(() => {
    var datas = { 'courseId': props['match']['params']['VisionmdsTestsid'], 'skip':0,'limit':10, onlyInfo: true }
    console.log('props-----------------------', props)
    console.log(props.match.params.VisionmdsTestsid, 'visiontests')
    //change to get
    bijBolPost('getEntranceCourseByIdTests', datas).then(res => {
      console.log("ressss>>>>>>>>>-----------------------------",res)
      var testinfo = res.data.data.testsIds;
      setTests(testinfo)
    })
    .catch((err) => {
      console.log("err>>>>>>>>>-----------------------------",err)
      console.log(err)
    })
  },[props])

    return (
        <Row className="mt-lg-3 mx-lg-0 mt-4 mx-0">
          {tests.map((test) => (
            <div className="col-lg-4 col-12  pl-lg-4 pr-lg-4"
            key={test._id}>
              <div
                className="card border-0 shadow mb-4"
                style={{ height: '291px' }}
              >
                <div className="bg-dark p-lg-4 p-4 card-border-radius">
                  <div className="row">
                    <div className="col-12">
                      <h6 className="text-white">{test.name}</h6>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-lg-8 col-8">
                      <small className="text-white font-weight-light">
                        Date: {test.scheduleDate}
                      </small>
                    </div>
                    <div className="col-lg-4 text-right col-4">
                      <small className="text-danger">Active</small>
                    </div>
                  </div>
                </div>

                <div className="bg-white">
                  <div className="row mt-lg-4 mt-4 mx-0 pl-lg-4">
                    <div className="col-lg-6 col-6 pl-5 pl-lg-1">
                      <ul>
                        <li>3 Synopsis</li>
                        <li className="mt-lg-3">2 Videos</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-6">
                      <ul>
                        <li>{test.totalQuestions} Questions</li>
                        <li className="mt-lg-3">{test.duration} Minutes</li>
                      </ul>
                    </div>
                  </div>
                  <div className="row mt-lg-3 mt-3 mb-3 mx-0 mb-lg-3 align-items-center">
                    <div className="col-lg-6 col-6">
                      <a style={{ color: '#0555E2' }} href>
                        <u style={{ color: '#0555E2' }}>How to prepare?</u>
                      </a>
                    </div>
                    <div className="col-lg-6 text-right col-6">
                      <Link to={`/testInfo/${test._id}`}>
                        <button
                          type="button"
                          className="btn btn-danger border-0"
                          style={{ background: '#0555E2' }}
                        >
                          Take Test
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Row>
    )
}

export default VisionmdsTests;