import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {profileImgHost} from 'assets/data/constants';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';

const ProfileDashboard = (props) => {
  const [dashBoardDetails, setDashBoardDetails] = useState({});
  const userSelector = useSelector((state) => state['Profile'])

  useEffect(() => {
    console.log('props => ',props)
    setDashBoardDetails(props['dashBoardDetails'])
  }, [props])


    return (
        <Card  className="col-lg-10 p-0 my-4" elevation="6" sx={{ borderRadius: 5 }} >
        <div className="bg-dark p-lg-3 p-2 card-border-radius">
          <div className="row align-items-center">
            <div className="col-lg-7 col-7 text-left">
              <div className="row align-items-center">
              <div className="col-4 text-white font-weight-light ">
                {/* <img class="border-radius" src={profileImgHost+userSelector['profileImage']} /> */}
                <Avatar sx={{ width: 56, height: 56 }} alt="Profile" src={profileImgHost+userSelector['profileImage']} />
              </div>
              <div className="col-8 column align-items-center">
              <div style={{ fontSize: '12px'}} className="text-white font-weight-light">
              {userSelector['userName']}
              </div>
              <div style={{ fontSize: '10px'}} className="text-white font-weight-light">
              {userSelector['email']}
              </div>
              </div>
              </div>
            </div>
            <div className="vr" style={{ height: '45px' }}></div>
            <div className="col-lg-4 text-center col-4">
              <h4 className="m-0 text-danger">{dashBoardDetails['Rank']}</h4>
              <small style={{fontSize:'70%', fontWeight:'2.3'}} className="text-white font-weight-light">
                All India Rank
              </small>
            </div>
          </div>
        </div>
        <div className="bg-white p-lg-3 p-3 card--border-radius">
          <div className="row align-items-center justify-content-around">
            <div className="col-lg-6 col-6 text-center p-0">
              <h4 className="m-0 text-danger">{dashBoardDetails['attemptTest']}</h4>
              <div style={{fontSize:'70%', fontWeight:'500 !important'}}>
                No of Q/A Attempted
              </div>
              <hr className="solid"></hr>
              <h4 className="m-0 text-danger">{dashBoardDetails['numberOfFailTests']}</h4> 
              <div style={{fontSize:'70%', fontWeight:'500 !important'}}>
                No of Failed Tests
              </div>
            </div>
            <div className="vr" style={{ height: '105px' }}></div>
            <div className="col-lg-5 text-center col-5 p-0">
              <h4 className="m-0 text-danger">{dashBoardDetails['numberOfPassTests']}</h4>
              <div style={{fontSize:'70%', fontWeight:'500 !important'}}>
                No of Pass Tests
              </div>
              <hr className="solid"></hr>
              <h4 className="m-0 text-danger">{dashBoardDetails['totalTestCount']}</h4>
              <div style={{fontSize:'70%', fontWeight:'500 !important'}}>
                No of total Test Count
              </div>
            </div>
          </div>
        </div>
        </Card>)

}

export default ProfileDashboard