/* eslint-disable no-unreachable */
import React, { useState, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import { Container, Card } from 'react-bootstrap';
import 'swiper/components/navigation/navigation.scss';
import Livetests from 'components/Livetests';
import Loading from 'components/Loading';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.scss';
import ModalPdfViewer from 'components/ModalPdfViewer';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CustomVideo from 'components/CustomVideo';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { bijBolPost } from 'services/api';

const Search = () => {
  //   const dispatch = useDispatch();
  const searchSelector = useSelector((state) => state['Search']);
  const [isLoading, setIsLoading] = useState(true);
  const [searchData, setSearchData] = useState([]);
  const [currentVideo, setCurrentVideo] = useState({});
  const [open, setOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [isOpenVid, setIsOpenVid] = useState(false);
  let history = useHistory();

  const userSelector = useSelector((state) => state['Profile']);

  const defaultVideoThumbnail =
    'https://so-ham.in/wp-content/themes/uploader/images/video_default_thumb.png';
  const defaultPdfThumbnail =
    'https://www.myesr.org/sites/default/files/media-icons/generic/application-pdf.png';

  const pickDefaultKeyType = (keyType) => {
    switch (keyType) {
      case 'video':
        return defaultVideoThumbnail;
        break;
      case 'pdf':
        return defaultPdfThumbnail;
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    console.log('searchSelector => ', searchSelector);
    setIsLoading(true);

    bijBolPost('library_Search', { name: searchSelector['searchInputValue'] })
      .then((res) => {
        console.log(
          'library_Search inside Search after props change=>',
          res['data']['data']
        );
        setSearchData(res['data']['data']);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log('Error in Search MDS => ', e);
      });
  }, [searchSelector]);

  const handleClickOpen = (data, type) => {
    console.log(type, 'type');
    if (type === 'pdf') {
      console.warn(data);

      if (userSelector && userSelector['profileImage']) {
        setPdfUrl(data['key']);
        setOpen(true);
      } else {
        history.push('/Login');
      }
    } else if (type === 'video') {
      if (userSelector && userSelector['profileImage']) {
        setCurrentVideo({ key: data['key'] });
        setIsOpenVid(true);
      } else {
        history.push('/Login');
      }
    } else if (type === 'topic') {
      history.push(`/TutorialsTopics/${data._id}/${data['subjectId']['_id']}`);
    } else if (type === 'tests') {
      history.push(`/testInfo/${data._id}`);
    } else if (type === 'entrances') {
      history.push(`/VisionMdsDashboard/${data._id}`);
    } else if (type === 'subjects') {
      console.warn(`/TutorialsTests/${data._id}`);
      history.push(`/TutorialsTests/${data._id}`);
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className='fullscreen'>
          <Loading />
        </div>
      ) : searchData.length === 0 ? (
        <div className='fullscreen'>No Data Found</div>
      ) : (
        Object.keys(searchData).map((data, idx) => {
          return (
            <div key={idx} className='pt-4'>
              <div className='col-lg-10 col-8'>
                <h6 className='font-weight-bold'>
                  {searchData[data].length === 0
                    ? `No Data for ${data.charAt(0).toUpperCase() + data.slice(1)}`
                    : data.toUpperCase()}
                </h6>
              </div>
              {data === 'test' ? (
                <Container fluid>
                  <Livetests data={searchData[data]}></Livetests>
                </Container>
              ) : (
                <div>
                  <Container fluid>
                    <Swiper
                      spaceBetween={10}
                      slidesPerView={3}
                      navigation
                      autoplay={true}
                      breakpoints={{
                        320: {
                          width: 350,
                          slidesPerView: 3,
                        },
                        768: {
                          width: 768,
                          slidesPerView: 3,
                        },
                        1024: {
                          width: 1024,
                          slidesPerView: 4,
                        },
                      }}
                    >
                      {searchData[data].map((result, index) => (
                        <SwiperSlide key={index} virtualIndex={index}>
                          {/* <div class="col-lg-12 d-flex align-items-stretch"> */}
                          <Card
                            className='cardClass'
                            onClick={() => handleClickOpen(result, data)}
                          >
                            <div style={{ textAlign: 'center' }}>
                              <Card.Img
                                variant='top'
                                src={
                                  result['image']
                                    ? result['image']
                                    : pickDefaultKeyType(result['keyType'])
                                }
                              />
                            </div>

                            <div style={{ height: '70px', fontSize: '15px' }}>
                              <Card.Text className='card-description'>
                                {result.name}
                              </Card.Text>
                            </div>
                          </Card>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Container>
                </div>
              )}
            </div>
          );
        })
      )}
      <ModalPdfViewer
        open={open}
        onCloseModal={(e) => setOpen(e)}
        pdfUrl={pdfUrl}
      />
      {isOpenVid && (
        <Dialog
          fullScreen={true}
          onClose={() => setIsOpenVid(false)}
          aria-labelledby='customized-dialog-title'
          open={isOpenVid}
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <IconButton
              aria-label='close'
              onClick={() => setIsOpenVid(false)}
              sx={{
                position: 'absolute',
                right: 8,
                zIndex: 12,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent textAlign='center' class='m-3'>
            {/* <VideoSynops url={currentVideo.key} /> */}
            <CustomVideo videoInfo={{ videoLinkObj: currentVideo.key }} />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default Search;
