import React from 'react'
import { Row } from 'react-grid-system'

const ScheduledTests = () => {
    return (
      <div>
        <Row className="mx-lg-5 mt-lg-5 mx-0 mt-5">
          <div
            className="card col-lg-12 border-0  shadow"
            style={{ borderRadius: '10px' }}>
            <div className="">
              <h5 className="text-dark mt-lg-4 mt-4 text-color-pink font-weight-600">
                Scheduled New Tests
              </h5>
            </div>
            <Row className="mt-lg-4 mt-4">
              <div className="col-lg-4">
                <div>
                  <label className="font-weight-normal">Subject</label>
                </div>
                <div>
                  {' '}
                  <select className="form-control">
                    <option>Select Subject</option>
                    <option>Brain</option>
                    <option>Anatomy</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-4 mt-3 mt-lg-0">
                <div>
                  <label className="font-weight-normal">Topic</label>
                </div>
                <div>
                  {' '}
                  <select className="form-control">
                    <option>Select Topic</option>
                    <option>Brain</option>
                    <option>Anatomy</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-4 mt-3 mt-lg-0">
                <div>
                  <label className="font-weight-normal">Test</label>
                </div>
                <div>
                  {' '}
                  <select className="form-control">
                    <option>Select Test</option>
                    <option>Brain</option>
                    <option>Anatomy</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-4 mt-lg-3 mt-3 ">
                <div>
                  <label className="font-weight-normal">Date</label>
                </div>
                <div>
                  <input type="date" className="form-control" />
                </div>
              </div>
              <div className="col-lg-4 mt-lg-3 mt-3">
                <div>
                  <label className="font-weight-normal">Time</label>
                </div>
                <div>
                  <input type="time" className="form-control" />
                </div>
              </div>
              <div className="col-lg-4 mt-lg-3 mt-3">
                <div>
                  <label className="font-weight-normal">Show Remainder</label>
                </div>
                <div>
                  <input type="time" className="form-control" />
                </div>
              </div>
              <div className="col-lg-4 mt-lg-3 mt-3">
                <div>
                  <label className="font-weight-normal">Notes</label>
                </div>
                <div>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </Row>
            <Row className="mt-lg-4 mb-lg-4 mt-3 mb-4">
                <div className="col-12 text-lg-left text-center">
                    <button type="submit" className="btn btn-default pl-5 pr-5" style={{background: '#0555E2', color: '#fff'}}>Schedule Test</button>
                </div>
            </Row>
          </div>
        </Row>
      </div>
    )
}

export default ScheduledTests;