import { bijBolPost } from 'services/api';

export const checkAttemptedQuestion = async (questionData) => {
  console.log(questionData, 'questionDataservice');
  let data = {
    entranceCourseId:
      questionData.testType === 'Subject-Test'
        ? questionData['topicId']['_id']
        : questionData['entranceCourseId']['_id'],
    testId: questionData['_id'],
    resumeTest: questionData.testType === 'Subject-Test' ? false : true,
  };
  console.log(data, 'dataservice');
  let attemptedQuestionRes = await bijBolPost('getRetakeTests', data);
  console.log('attemptedQuestionRes =>', attemptedQuestionRes);

  return attemptedQuestionRes['data']['data'];
};
