import { combineReducers } from "redux";
import SearchReducer from "./searchReducer";
import ProfileReducer from "./profileReducer";
import QueryList from "./queryList";

const reducers = combineReducers({
  Search: SearchReducer,
  Profile: ProfileReducer,
  QueryList: QueryList
  //other reducers come here...
});

export default reducers;