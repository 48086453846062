import React from 'react';
import { useState, useEffect } from 'react';
import { Container, Row, ProgressBar } from 'react-bootstrap';
import { bijBolPost } from 'services/api';
import Loading from 'components/Loading';
import QuestionSelection from 'components/QuestionSelection';
import QuestionStatusModal from 'components/QuestionStatusModal';
import { testStatusList } from 'assets/data/constants';
import { checkAttemptedQuestion } from 'utils/questions.js';
import { useHistory } from 'react-router-dom';
import { useIsAuthenticated } from 'hooks/auth';

let quizsingleProgress = 0;
let temptime = 0;
const Questions = (props) => {
  let history = useHistory();
  const [testObj, setTestObj] = useState({});
  const [time, setTime] = useState({ minutes: '', seconds: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [showQuestionStatusModal, setShowQuestionStatusModal] = useState(false);
  const [questions, setQuestions] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedOptionSet, setselectedOptionSet] = useState('');
  const [attemptedQuestionMap, setAttemptedQuestionMap] = useState(new Map());
  const [reset, setReset] = useState(false);
  const isAuth = useIsAuthenticated();

  useEffect(async () => {
    let datas = { testId: props.match.params.Questionid, onlyInfo: false };
    if (!isAuth) {
      //alert('Please Login')
      props.history.push('/Login');
    } else {
      const questionRes = await bijBolPost('getEntranceTestById', datas);

      console.log('---------questions', questionRes.data.data);
      const testObj = questionRes.data.data;
      let questions = questionRes.data.data['questions'];
      setTestObj(questionRes.data.data);
      setQuestions(questions);

      settingAttemptedQuestion(questionRes.data.data);

      //timer implemenations
      // startTimer();

      if (testObj['isPremium'] && testObj['showAnswer'])
        props.history.push(
          `/Subscription/${testObj['topicId']._id}/subject/?backUrl=${window.location.pathname}`
        );
      if (testObj['isPremium'] && !testObj['showAnswer'])
        props.history.push(
          `/Subscription/${testObj['entranceCourseId']._id}/visionmds/?backUrl=${window.location.pathname}`
        );

      let isResumeTest = false;

      switch (testObj['userTestStatus']) {
        case 'takeTest':
          isResumeTest = false;
          break;
        case 'resumeTest':
          isResumeTest = true;
          break;
        case 'retakeTest':
          isResumeTest = false;
          break;
      }

      let entranceCourseId = testObj.showAnswer
        ? testObj['topicId']._id
        : testObj.entranceCourseId._id;
      const startTestPrams = {
        testId: testObj['_id'],
        resumeTest: isResumeTest,
        topicTestFlag: testObj['showAnswer'],
        entranceCourseId: entranceCourseId,
      };
      startTest(startTestPrams);
    }
    // return () => {
    //   console.log('come');
    //   clearInterval(updateCountdown);
    // };
  }, [props.history, props.match.params.Questionid]);
  useEffect(() => {
    let intervalId = setInterval(updateCountdown, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  //Setting Attempted question Data for Resume Test if exist for User
  const settingAttemptedQuestion = async (data) => {
    let checkAttemptedQuestionRes = {};
    // setAttemptedQuestion(new Array(questions.length).fill({})) // declaring the length of attempted and questions as same
    if (data['userTestStatus'] === 'resumeTest' && !data['showAnswer'])
      checkAttemptedQuestionRes = await checkAttemptedQuestion(data);

    //Creating HasMap for attempted question
    if (
      checkAttemptedQuestionRes['questionInfo'] &&
      checkAttemptedQuestionRes['questionInfo'].length > 0
    ) {
      let attemptedQuestionResult = new Map(
        checkAttemptedQuestionRes['questionInfo'].map((i) => [
          i.questionId,
          i.reviewObj,
        ])
      );
      setAttemptedQuestionMap(attemptedQuestionResult);
    }
    console.log(
      "checkAttemptedQuestionRes['remainingTime'] =>",
      checkAttemptedQuestionRes['remainingTime']
    );
    temptime = checkAttemptedQuestionRes['remainingTime']
      ? checkAttemptedQuestionRes['remainingTime'] * 60
      : data.duration * 60;
    console.log(data.duration, 'temptime');
    quizsingleProgress = 100 / questions['length'];
    console.log('quizsingleProgress-----', quizsingleProgress);
    setIsLoading(false);
  };

  const startTest = (data) => {
    console.log('start test params', data);
    if (data['userTestStatus'] !== 'resumeTest') {
      bijBolPost('onStartTests', data)
        .then((res) => {
          console.log('Test Started => ', res['data']['data']);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const submitAnswer = (currentData, type) => {
    if (!currentData) return setReset(true);

    console.log(testObj);
    let entranceCourseId = testObj.showAnswer
      ? testObj['topicId']._id
      : testObj.entranceCourseId._id;
    let datas = {
      entranceCourseId: entranceCourseId,
      testId: props.match.params.Questionid,
      selectedOptions: currentData['selectedOptions'],
      questionId: questions[currentIndex]['_id'],
      topicTestFlag: testObj.showAnswer,
    };
    console.log('on submit data => ', datas);

    if (type === 'reset') {
      datas['updateType'] = 'reset';
      attemptedQuestionMap.delete(questions[currentIndex]['_id']);
    }

    bijBolPost('onQuestionSelectionTests', datas)
      .then((res) => {
        console.log('After question submitted => ', res['data']);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const testEnd = () => {
    console.log('on test end');
    setIsLoading(true);
    let entranceCourseId = testObj.showAnswer
      ? testObj['topicId']._id
      : testObj.entranceCourseId._id;
    let dataass = {
      entranceCourseId: entranceCourseId,
      testId: testObj['_id'],
      topicTestFlag: testObj.showAnswer,
    };
    console.log(
      'score card => ',
      `/Scorecard/${testObj['_id'] + `/` + entranceCourseId}`
    );

    bijBolPost('testsEnd', dataass)
      .then((res) => {
        console.log('test end res => ', res.data);
        setIsLoading(false);
        if (res.data.status === true) {
          history.push(`/Scorecard/${testObj._id + `/` + entranceCourseId}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onNextClick = () => {
    console.log('clicked Next,', selectedOptionSet);

    if (selectedOptionSet) {
      setAttemptedQuestionMap(
        new Map(
          attemptedQuestionMap.set(questions[currentIndex]['_id'], {
            correctAnswerIds: [questions[currentIndex]['correctAnswerIds']],
            isCorrect: isCorrect(currentIndex),
            question: questions[currentIndex],
            selectedOptions: selectedOptionSet,
          })
        )
      );
      console.log('attemptedQuestionMap =>', attemptedQuestionMap);

      submitAnswer(attemptedQuestionMap.get(questions[currentIndex]['_id']));
    }
    //On Last question Next it will directly submit
    if (currentIndex === questions.length - 1) {
      testEnd();
      return;
    } else {
      setselectedOptionSet('');
      setCurrentIndex(currentIndex + 1);
    }
  };

  const onResetClick = () => {
    console.log('clicked Reset,', selectedOptionSet);
    if (currentIndex === questions[questions.length - 1]) return;

    if (selectedOptionSet) {
      submitAnswer(
        attemptedQuestionMap.get(questions[currentIndex]['_id']),
        'reset'
      );
      console.log('attemptedQuestionMap =>', attemptedQuestionMap);
    }
    setselectedOptionSet('');
    setCurrentIndex(currentIndex);
  };

  //Responds to the click of the finish button
  // const startTimer = () => {
  //   console.log('temptime-------00------', temptime);
  //   setInterval(updateCountdown, 1000);
  // };

  const updateCountdown = () => {
    // console.log('temptime------01--', temptime)
    var minutes = Math.floor(temptime / 60);
    var seconds = `${temptime % 60}`.slice(-2);
    seconds = seconds < 10 ? '0' + seconds : seconds;
    console.log(minutes, seconds, 'timeser');

    if (temptime < 0) {
      setTime(
        {
          minutes: 0,
          seconds: 0,
        },
        () => {
          alert('test has ended!');
          this.props.history.push('/');
        }
      );
    } else {
      setTime({
        minutes,
        seconds,
      });
      temptime--;
    }
  };

  const choosenOption = (optionSet) => {
    setselectedOptionSet(optionSet);
  };

  const isCorrect = (idx) => {
    if (selectedOptionSet === questions[idx]['correctAnswerIds'][0]) return 1;

    if (selectedOptionSet !== questions[idx]['correctAnswerIds'][0]) return 0;
  };

  return (
    <Container fluid>
      {isLoading ? (
        <div className='fullscreen'>
          <Loading />
        </div>
      ) : (
        <Row className='mx-lg-3 mt-lg-5 mb-lg-5 mt-5 mb-4'>
          <div className='col-lg-12 col-12'>
            <div className='card border-0 shadow '>
              <div className='bg-dark p-lg-4 card-border-radius p-3'>
                <Row className='align-items-center'>
                  <div className='col-lg-9 col-7'>
                    <p className='text-white mb-0'>{testObj.name}</p>
                    <small className='text-white font-weight-light'>
                      {`Question ${currentIndex + 1} of ${questions.length}`}
                    </small>
                    <ProgressBar
                      variant='danger'
                      className='mt-lg-3 mt-3'
                      now={(attemptedQuestionMap.size / questions.length) * 100}
                    />
                  </div>
                  <div className='col-lg-3 text-lg-center col-4 text-center'>
                    <p className='font-weight-bold text-white'>
                      {time.minutes}:{time.seconds}
                    </p>
                    <button
                      type='button'
                      className='btn btn-danger pl-4 pr-4 text-lg-center'
                      style={{ background: '#0555E2' }}
                      onClick={() =>
                        currentIndex === testObj['totalQuestionsCount'] - 1
                          ? onNextClick()
                          : testEnd()
                      }
                    >
                      Submit
                    </button>
                  </div>
                </Row>
              </div>
            </div>
            <div className='bg-white shadow card-bottom-border-radius'>
              {questions.length > 0 && (
                <QuestionSelection
                  reset={reset}
                  selectedOption={choosenOption}
                  questionNumber={currentIndex + 1}
                  selectedOptionByUser={
                    attemptedQuestionMap.get(questions[currentIndex]['_id'])
                      ? attemptedQuestionMap.get(
                          questions[currentIndex]['_id']
                        )['selectedOptions']
                      : ''
                  }
                  isSelectable={true}
                  question={questions[currentIndex]}
                  showAnsOnlyAfterClick={testObj.showAnswer ?? true}
                />
              )}
              <Row className='pt-lg-4 mx-0 mx-lg-2 pb-lg-4 justify-content-center'>
                {/* <div className="col-lg-2 col-4 mt-3 mt-lg-0 mt-md-0">
                  <div>
                    <button
                      type="button"
                      className="btn btn-info border-0 ml-lg-0 text-nowrap font-size-14px btn-block"
                      // onClick={this.handleClear}
                      style={{ background: '#e4e4e4', color: '#ff0053' }}
                    >
                      <i className="fa fa-eye" aria-hidden="true"></i> Review
                    </button>
                  </div>
                </div> */}
                <div className='col-lg-2 col-6 mt-3 mt-lg-0 mt-md-0'>
                  <div>
                    <button
                      type='button'
                      onClick={() => {
                        setShowQuestionStatusModal(!showQuestionStatusModal);
                      }}
                      className='btn btn-info border-0 pl-lg-4 pr-lg-4 pl-2 pr-2 ml-lg-0 text-nowrap font-size-14px btn-block'
                      style={{ background: '#e4e4e4', color: '#ff0053' }}
                    >
                      <i className='fa fa-filter mr-2' aria-hidden='true'></i>
                      Filter
                      <QuestionStatusModal
                        show={showQuestionStatusModal}
                        onClose={() =>
                          setShowQuestionStatusModal(!showQuestionStatusModal)
                        }
                        attemptedQuestion={attemptedQuestionMap}
                        questions={questions}
                        setCurrentIndex={(index) => setCurrentIndex(index)}
                      />
                    </button>
                  </div>
                </div>
                <div className='col-lg-2 col-6 mt-3 mt-lg-0 mt-md-0'>
                  <div>
                    <button
                      type='button'
                      className='btn btn-info border-0 pl-4 pr-4 ml-lg-0 text-nowrap font-size-14px btn-block'
                      style={{
                        background: 'rgb(228, 228, 228)',
                        color: 'rgb(255, 0, 83)',
                      }}
                      onClick={() => {
                        onResetClick({}, 'reset');
                      }}
                    >
                      <i className='fa fa-trash-o' aria-hidden='true'></i> Reset
                    </button>
                  </div>
                </div>

                <div className='col-lg-2 col-6 mt-3 mt-lg-0'>
                  <button
                    className={`ui inverted button btn btn-info border-0 pl-4 pr-4 btn-block ${
                      currentIndex !== 0 ? 'selected' : null
                    }`}
                    style={{ background: '#75757e' }}
                    onClick={() => {
                      if (currentIndex === 0) return;
                      setCurrentIndex(currentIndex - 1);
                    }}
                  >
                    Previous
                  </button>
                </div>
                <div className='col-lg-2 col-6 mt-3 mt-lg-0'>
                  <button
                    className={`ui inverted button btn btn-info border-0 pl-4 pr-4 btn-block ${
                      currentIndex !== testObj['totalQuestionsCount'] - 1
                        ? 'selected'
                        : null
                    }`}
                    style={{ background: '#75757e' }}
                    onClick={() => {
                      if (currentIndex === testObj['totalQuestionsCount'] - 1)
                        return;
                      onNextClick();
                    }}
                  >
                    Next
                  </button>
                </div>
              </Row>
              <div className='row align-items-center m-1 p-3'>
                {testStatusList.map((a, idx) => {
                  return (
                    <div
                      key={idx}
                      className='row col-lg-3 col-xs-6 col-6 gx-5 m-0 p-1'
                    >
                      <div style={a['style']}></div>
                      <div className='pl-2'>{a['status']}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Row>
      )}
    </Container>
  );
};

export default Questions;
