import React, { useEffect, useState } from 'react'
import { Row } from 'react-grid-system'
import { Link } from 'react-router-dom'
import { bijBolPost } from 'services/api';

const Tutorials = () => {
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    var datas = { skip: 0, limit: 25 }
    bijBolPost('getAllSubjects', datas).then(res => {
      //console.log('res.data.data--------hshsua a ---', res.data.data)
      setSubjects(res.data.data)
    }).catch(e => {
      console.log(e)
    })
  },[])

  return (
    <div
      className="card border-0 shadow mt-4 mx-lg-3"
      style={{ borderRadius: '10px' }}
    >
      <Row className="mt-lg-4 mx-lg-3 mt-4 mx-0">
        {subjects.map((sub, idx) => (
          <div key={idx} className="col-lg-2 col-3">
            <div className="border-0 mb-4" key={sub._id}>
              <div className="card-border-radius">
                <div className="row">
                  <div className="col-12 text-center">
                    <Link to={`/TutorialsTests/${sub._id}`}>
                      <img alt='' src={sub.image} className="icon-height border" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="bg-white">
                <div className="row mt-lg-4 mt-4">
                  <div className="text-center col-12">
                    <h6
                      className="text-dark small"
                      style={{ fontWeight: '500' }}
                    >
                      {sub.name}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Row>
    </div>
  )
}

export default Tutorials
