import React from 'react'
import { Row } from 'react-bootstrap'

const Error = () => {
  return (
    <div>
      <Row className="mx-lg-0 mt-lg-5 mb-lg-5 mt-5 mx-0">
        <div className="col-lg-2"></div>
        <div className="col-lg-8 col-12">
          <div className="card shadow border-0 p-4" style={{ borderRadius: '10px' }}>
            <img src="images/error.png" className="img-fluid" alt=''/>
          </div>
        </div>
        <div className="col-lg-2"></div>
      </Row>
    </div>
  )
}

export default Error