import React, { useEffect, useState } from 'react'
import { Row } from 'react-grid-system';
import { Container } from 'react-bootstrap'
import { bijBolPost } from 'services/api';
import Loading from 'components/Loading';
import ModalPdfViewer from 'components/ModalPdfViewer';
import Dialog from '@mui/material/Dialog';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { getLinkToTakeTest } from 'utils/link'
import CustomVideo from 'components/CustomVideo';
import PopUpInfo from './popUpInfo';
import moment from 'moment';
import { useIsAuthenticated } from "hooks/auth";

const Instructions = (props) => {
  const [pdfUrl, setPdfUrl] = useState("");
  const [testObj, setTestObj] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [isOpenVid, setIsOpenVid] = useState(false);
  const [openPopUpInfo, setOpenPopUpInfo] = React.useState(false);
  const [popUpInfo , setPopUpInfo] = useState({title: '', description: ''})
  const isAuth = useIsAuthenticated();
  useEffect(() => {
    console.log("verify =>", isAuth)
    if (!isAuth) {
      //alert('Please Login')
      props.history.push('/Login')
    }

    var datas = { testId: props.match.params.testid, onlyInfo: false }
    //change to get
    bijBolPost('getTestInfo', datas).then(res => {
      console.log('res.data.data setTestObj-----------', res.data.data)
      var Questions = res.data.data
      setTestObj(Questions)
      setIsLoading(false)
    }).catch(e => {
      console.log(e)
    })
  }, [props.match.params.testid])

  // modal popup start
  // eslint-disable-next-line no-unused-vars
  const handleOpenModal = (val) => {
    console.log(val)
    setIsOpenVid(true)
  }

  const handleClickOpen = (url) => {
    setPdfUrl(url)
    setOpen(true);
  };

  // eslint-disable-next-line no-unused-vars
  const handleOpenPopUpInfo = () => {
    setOpenPopUpInfo(true);
    
    if(moment(testObj['scheduleDate'], 'DD-MM-YYYY').isAfter(moment(new Date()))){
      setPopUpInfo({title: 'Coming soon..', description: 'This exam will be avaialble once it is realeased.'})
    }

    // if(moment(testObj['scheduleDate']).isBefore(moment(new Date()).format("DD-MM-YYYY"))){
    //   setPopUpInfo({title: 'Not Available', description: 'This test is not available'})
    // }

  };

  const handleClosePopUpInfo = () => {
    setOpenPopUpInfo(false);
  };

  return (
    <div>
                      
        <PopUpInfo
        info={popUpInfo}
        open={openPopUpInfo}
        onClose={handleClosePopUpInfo}
      />
      {isLoading ? <div className='fullscreen'><Loading /></div> :
        <Container fluid>
          <Row
            className="mx-lg-3 mt-lg-5 mb-lg-5 mt-5"
            key={testObj._id}
            id="instruction"
            style={{ display: 'block' }}>
            <div className="col-lg-12 col-12">
              <div className="card border-0 shadow">
                <div className="bg-dark p-lg-4 card-border-radius p-4">
                  <p className="text-white">{testObj.name}</p>
                  <small className="text-white font-weight-light mt-lg-3">
                    {testObj.scheduledDate}
                  </small>
                </div>
              </div>
              <div className="bg-white shadow card-bottom-border-radius">
                <Row className="p-lg-4 p-4">
                  <div className="col-lg-6 col-12">
                    <Row className="mt-lg-3 mt-3">
                      <div className="col-lg-4 col-9">
                        <div>
                          <small>
                            <i className="fa fa-file-text-o" aria-hidden="true"></i>
                            <span className="ml-lg-2 ml-2">
                              1 Synopsis Document
                            </span>
                          </small>
                        </div>
                      </div>
                      <div className="col-lg-8 text-lg-left col-3 text-right">
                        <div className="icon">
                          <button
                            className="ml-lg-5 font-weight-bold bg-transparent small border-0"
                            style={{ color: '#0555E2' }}
                            onClick={() => {
                              handleClickOpen(testObj['synopsisDocumentUrl'])
                            }} >
                            Open
                          </button>
                        </div>
                      </div>
                    </Row>

                    <Row className="mt-lg-3 mt-3">
                      <div className="col-lg-4 col-9">
                        <div>
                          <small>
                            <i className="fa fa-file-video-o" aria-hidden="true"></i>
                            <span className="ml-lg-2 ml-2">
                              1 Preparation Video
                            </span>
                          </small>
                        </div>
                      </div>
                      <div className="col-lg-8 text-lg-left col-3 text-right">
                        <div className="icon">
                          <button
                            className="ml-lg-5 font-weight-bold small border-0 bg-transparent"
                            style={{ color: '#0555E2' }}
                            onClick={() => {
                              handleOpenModal('video')
                            }}>
                            Open
                          </button>
                          {isOpenVid && <Dialog
                            fullScreen={true}
                            onClose={() => setIsOpenVid(false)}
                            aria-labelledby="customized-dialog-title"
                            open={isOpenVid}>
                            <DialogTitle sx={{ m: 0, p: 2 }}>
                              <IconButton
                                aria-label="close"
                                onClick={() => setIsOpenVid(false)}
                                sx={{
                                  position: "absolute",
                                  right: 8,
                                  zIndex: 12,
                                  top: 8,
                                  color: (theme) => theme.palette.grey[500],
                                }}>
                                <CloseIcon />
                              </IconButton>
                            </DialogTitle>
                            <DialogContent textAlign="center" class="m-3">
                              {/* <VideoSynops url={currentVideo.key} /> */}
                              <CustomVideo videoInfo={{ videoLinkObj: testObj['synopsisVideoUrl'] }} />
                            </DialogContent>
                          </Dialog>}
                        </div>
                      </div>
                    </Row>
                  </div>
                  <div className="col-lg-6 col-12 mt-4 mt-lg-0">
                    <Row className="mt-lg-3 mt-3">
                      <div className="col-lg-4 col-5">
                        <small>Subject</small>
                      </div>
                      <div className="col-lg-6 col-7 text-right text-lg-left">
                        <small className="font-weight-bold">{testObj.name}</small>
                      </div>
                    </Row>
                    <Row className="mt-lg-3 mt-3">
                      <div className="col-lg-4 col-5">
                        <small>No. of Questions</small>
                      </div>
                      <div className="col-lg-6 col-7 text-right text-lg-left">
                        <small className="font-weight-bold">
                          {testObj.totalQuestionsCount} Questions
                        </small>
                      </div>
                    </Row>
                    <Row className="mt-lg-3 mt-3">
                      <div className="col-lg-4 col-5">
                        <small>Duration</small>
                      </div>
                      <div className="col-lg-6 col-7 text-right text-lg-left">
                        <small className="font-weight-bold">
                          {testObj.duration} Minutes
                        </small>
                      </div>
                    </Row>
                  </div>
                </Row>
                <Row className="mt-lg-3 p-lg-4 p-4">
                  <div className="col-lg-12 col-12">
                    <h6 className="font-weight-bold">Instructions</h6>
                  </div>
                  <div className="col-lg-12">
                    <ul className="ml-lg-3 mt-3 ml-3">
                      <li className="mt-lg-3 mt-3">
                        Click Start Test to begin test.
                      </li>
                      <li className="mt-lg-3 mt-3">
                        Timer will start immediately after the questions are
                        loaded.
                      </li>
                      <li className="mt-lg-3 mt-3">Answer all questions.</li>
                      <li className="mt-lg-3 mt-3">
                        Once you are done with the test, submit the test. You will
                        be redirected to the result page.
                      </li>
                      <li className="mt-lg-3 mt-3">
                        If time is up, the test will submit automatically.
                      </li>
                      <li className="mt-lg-3 mt-3">
                        Once you complete the test, your score will be available
                        immediately.
                      </li>
                    </ul>
                  </div>
                </Row>
                <Row className="p-lg-4 pb-4">
                  <div className="col-lg-12 col-12 text-center text-lg-left">
                      <button
                        type="button"
                        className="btn btn-danger border-0 pl-lg-5 pr-lg-5 pl-5 pr-5"
                        onClick={() => {

                          if(!testObj['showAnswer'] && moment(testObj['scheduleDate'], 'DD-MM-YYYY').isAfter(moment(new Date()))) return handleOpenPopUpInfo(); 

                          props.history.push(getLinkToTakeTest({
                            showAnswer: testObj['showAnswer'],
                            isPremium: testObj['isPremium'],
                            id: testObj['_id'],
                            typeId: testObj['showAnswer'] ? testObj['subjectId']['_id'] : testObj['entranceCourseId']['_id'],
                            testPath: '/Questions'
                          }))
                        }}
                        style={{ background: '#0555E2' }}>
                        Start Test
                      </button>
                  </div>
                </Row>
              </div>
            </div>
          </Row>
          {/* {Modal} */}
          <ModalPdfViewer open={open} onCloseModal={(e) => setOpen(e)} pdfUrl={pdfUrl} />
          {/* Questions */}

        </Container>}
    </div>
  )
}

export default Instructions
