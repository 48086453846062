import React, { useState } from 'react'
import { useTransition, animated } from 'react-spring'
import { Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logoImg from 'assets/images/logo.png';
import menuImg from 'assets/images/menu.png';
import backImg from 'assets/images/back.png';
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from "react-router-dom";
import { useLogout } from '../hooks/auth'

function Mobilemenu() {
  const [showMenu, setshowMenu] = useState(false)
  const userSelector = useSelector((state) => state['Profile'])
  const history = useHistory();
  const location = useLocation();
  const isExternal = useSelector((state) => state['QueryList']['ext'])
  // console.log('isExternal => ', isExternal);

  const logout = useLogout();

  const transitions = useTransition(showMenu, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })
  const overlayTransitions = useTransition(showMenu, {
    from: { opacity: 0, transform: 'translate(-100%)' },
    enter: { opacity: 1, transform: 'translate(-0%)' },
    leave: { opacity: 0, transform: 'translate(-100%)' },
  })

  const showBackButton = () => {
    // console.log('path', location['pathname'])
    if (location['pathname'] === '/home') {
      return true
    }
    if (location['pathname'] === '/Scorecard') {
      return true
    }
    return false;
  }

  return (
    <div>
      <Row className="mt-4">
        <div className="col-4">
          <nav className="float-left">
            {showBackButton() && <div className="text-right" onClick={() => {
              setshowMenu(!showMenu);
            }}>
              <img src={menuImg} alt="logo" />
            </div>}
            {!showBackButton() && <div className="text-right" onClick={() => {
              if (location['pathname'].includes('/Scorecard') || location['pathname'].includes('/Answerskey')) {
                history.push('/home')
              } else {
                history.goBack();
              }
            }}>
              <img src={backImg} alt="logo" />
            </div>}

            {transitions(
              (styles, item) =>
                item && (
                  <animated.div
                    style={styles}
                    className="bg-white w-75 shadow fixed-top menu">
                    <ul className="p-4" style={{ fontSize: '18px' }} onClick={() => setshowMenu(false)}>
                      {!userSelector && <li className="list-style-type-none">
                        <Link to="/Login"><button className="btn btn-danger border-0 pl-5 pr-5 pt-3 pb-3">
                          Login/Register
                        </button></Link>
                      </li>}
                      <Link to="/home"><li className="list-style-type-none">Home</li></Link>
                      <Link to="/about"><li className="list-style-type-none">Bijbol Education</li></Link>
                      <li className="list-style-type-none">Case Studies</li>
                      <li className="list-style-type-none">Jobs</li>
                      <li className="list-style-type-none">Consultants</li>
                      <li className="list-style-type-none">Business Network</li>
                      <li className="list-style-type-none">Events</li>
                      <li className="list-style-type-none">Dental Labs</li>
                      <li className="list-style-type-none">Market Place</li>
                      <Link to="/Subscription">
                        <li className="list-style-type-none text-dark">
                          Subscription
                        </li>
                      </Link>
                      <Link to="/PaymentHistory">
                        <li className="list-style-type-none text-dark">
                          Payment History
                        </li>
                      </Link>
                      {!isExternal && userSelector && userSelector['profileImage'] &&
                        <li className="list-style-type-none text-dark" onClick={logout}>
                          Logout
                        </li>}
                    </ul>
                  </animated.div>
                ),
            )}
            {overlayTransitions(
              (styles, item) =>
                item && (
                  <animated.div
                    style={styles}
                    className="w-100 shadow fixed-top menu-overlay"
                    onClick={() => setshowMenu(false)}
                  ></animated.div>
                ),
            )}
          </nav>
        </div>

        <div className="col-8">
          <Link to="/home">
            {!isExternal && <img src={logoImg} alt="logo" />}
          </Link>
        </div>


      </Row>
    </div>
  )
}
export default Mobilemenu
