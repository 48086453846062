/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { bijBolPost } from '../services/api';
import MomentUtils from '@date-io/moment';
import ProfileDashboard from 'components/ProfileDashboard';
import TestCard from 'components/TestCard';
import moment from 'moment';
import Loading from 'components/Loading';
import { useIsAuthenticated } from "hooks/auth";
import ReactPaginate from 'react-paginate';

import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const VisionMdsDashboard = (props) => {
  const [dateValue, setDateValue] = useState(new Date());
  const [dashBoardDetails, setDashBoardDetails] = useState({});
  //const [calenderDate, setCalenderDate] = useState([]);
  const [tests, setTests] = useState([]);
  const [upcommingtests, setUpcommingTests] = useState([]);
  const [completedtests, setCompletedTests] = useState([]);

  const [isLoadingdashboardDetails, setIsLoadingdashboardDetails] = useState(true);
  const [isLoadingRecommended, setIsLoadingRecommended] = useState(true);
  const [activeKey, setActiveKey] = useState('Recommended')
  // eslint-disable-next-line no-unused-vars
  const isAuth = useIsAuthenticated();

  const [page, setPage] = useState([]);
  const [pages, setPages] = useState([]);
  
  
  
  useEffect(() => {
    if (isAuth) {
      bijBolPost('getUserEntranceDashboardDetails', { "entranceCourseId": props.match.params.VisionmdsTestsid }).then(res => {
        console.log('res getUserEntranceDashboardDetails >>>', res['data']['data'])
        setDashBoardDetails(res['data']['data']);
        setIsLoadingdashboardDetails(false);
      }).catch(err => {
        console.log("err>>>>>>>>>-----------------------------", err)
        console.log(err)
        setIsLoadingdashboardDetails(false);
      });
    } else {
      setIsLoadingdashboardDetails(false);
    }

    bijBolPost('getEntranceDates', { "entranceCourseId": props.match.params.VisionmdsTestsid }).then(res => {
      testDetails(res['data']['data'][0]['scheduleDate'], props.match.params.VisionmdsTestsid)
      setDateValue(new Date(res['data']['data'][0]['scheduleDate'].split("-").reverse().join("-")))
      //setCalenderDate(res['data']['data'].map(a => a['scheduleDate']));
    }).catch(err => {
      console.log("err>>>>>>>>>-----------------------------", err)
      console.log(err)
    });

    testCompletedDetails(props.match.params.VisionmdsTestsid)
    testUpcommingDetails(props.match.params.VisionmdsTestsid)

  }, [])

  const handlePageClick = (event) => {
    let page = event.selected;
    setPage(page)

    let skipLimit = { "skip": page*10, "limit": 10 }

    if(activeKey === "Recommended"){

      testDetails(moment(dateValue).format('DD-MM-YYYY'), props.match.params.VisionmdsTestsid,skipLimit)
    }

    if(activeKey === "Upcoming"){
      testUpcommingDetails(props.match.params.VisionmdsTestsid, skipLimit)
    }
    if(activeKey === "Completed"){

     
      testCompletedDetails(props.match.params.VisionmdsTestsid, skipLimit) 
    }
    
}
  const testDetails = (dateVal, courseId, skipLimit = { "skip": 0, "limit": 10 }) => {

    bijBolPost('getEntranceCourseByIdTests', {
      "courseId": courseId,
      "scheduleDate": dateVal, ...skipLimit
    }).then(res => {
      let testinfo = res.data.data.testsIds;

      setPages(Math.ceil(res.data.tCount/10));
      setPage(0);
      console.log('res Recommended >>>', res['data'])
     /*  if (testinfo && testinfo.length > 0) {
        setTests(tests.concat(testinfo))
      } */
      
      if(testinfo)
      setTests(testinfo)
      else
      {
        testinfo=[];
        setTests(testinfo)

      }

      setIsLoadingRecommended(false)
    }).catch(err => {
      console.log("err>>>>>>>>>----Recommended-------------------------", err)
      console.log(err)
    });
  }

  const testCompletedDetails = (courseId, skipLimit = { "skip": 0, "limit": 10 }) => {
    bijBolPost('getEntranceBysortTests', { "courseId": courseId, "sortType": "completed", ...skipLimit }).then(res => {

      setPages(Math.ceil(res.data.tCount/10));
      setPage(0);
      console.warn(page);
      let testinfo = res.data.data.testsIds;
      console.log('res Completed >>>', testinfo);
     /*  if (testinfo && testinfo.length > 0) {
        setCompletedTests(completedtests.concat(testinfo));
      }else{
        console.log('hasmore')
        // setCompletedTests(completedtests);
        setHasMoreCompleted(false);
      } */


      if(testinfo)
      setCompletedTests(testinfo)
      else
      {
        testinfo=[];
        setCompletedTests(testinfo)

      }
    }).catch(err => {
      console.log("err>>>>>>>>>----Completed-------------------------", err)
      console.log(err)
    });
  }

  const testUpcommingDetails = (courseId, skipLimit = { "skip": 0, "limit": 10 }) => {
    bijBolPost('getEntranceBysortTests', { "courseId": courseId, "sortType": "upcoming", ...skipLimit }).then(res => {
      setPages(Math.ceil(res.data.tCount/10));
      setPage(0);
      let testinfo = res.data.data.testsIds;
      console.log('res Upcoming >>>', testinfo);
      /* if (testinfo && testinfo.length > 0) {
        setUpcommingTests(upcommingtests.concat(testinfo))
      }else{
        console.log('hasmore')
        setUpcommingTests(upcommingtests)
        setHasMoreUpcoming(false)
      } */

      if(testinfo)
      setUpcommingTests(testinfo)
      else
      {
        testinfo=[];
        setUpcommingTests(testinfo)

      }

    }).catch(err => {
      console.log("err>>>>>>>>>----Upcoming-------------------------", err)
      console.log(err)
    });
  }

  const handlerTab = (key) => {
    console.log(key)
    let timer = setTimeout(() => {
      console.log('This will run after 1 second!')

      console.warn(timer)
    setActiveKey(key)

    setPage(0)
    


    let tabName = key;

    console.log("activeKey",activeKey)

    let skipLimit = { "skip": page*10, "limit": 10 }

    if(tabName === "Recommended"){

      testDetails(moment(dateValue).format('DD-MM-YYYY'), props.match.params.VisionmdsTestsid,skipLimit)
    }


    if(tabName === "Completed"){
      testCompletedDetails(props.match.params.VisionmdsTestsid, skipLimit) 
    }
    if(tabName === "Upcoming"){
      testUpcommingDetails(props.match.params.VisionmdsTestsid, skipLimit)
    }
    }, 3000);

    
  }

 

  return (

    <div className="m-lg-5 m-2" id="visionMdsDash" >
      <div className="row">
        <div className="col-12 col-lg-5">
          {isLoadingdashboardDetails ? <div ><Loading /></div> : (isAuth && 
            <ProfileDashboard dashBoardDetails={dashBoardDetails} />)}
          <div
            className="card card-radius shadow my-4 justify-content-center centerCalendar"
            style={{ maxWidth: 360 }}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                inputStyle={{ textAlign: 'center' }}
                autoOk
                orientation="landscape"
                // minDate = {dateValue}
                variant="static"
                openTo="date"
                value={dateValue}
                //shouldDisableDate={(date) => !calenderDate.includes(moment(date).format('DD-MM-YYYY'))}
                onChange={(date) => {
                  setIsLoadingRecommended(true)
                  testDetails(moment(date).format('DD-MM-YYYY'), props.match.params.VisionmdsTestsid)
                  setDateValue(date)
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className="col-lg-7">
          <Tabs
            defaultActiveKey={activeKey}
            transition={false}
            onSelect={(key) => handlerTab(key)}
            id="visiontab"
            className="row m-1 mt-3 col-lg-12 justify-content-center"
          ><Tab eventKey="Recommended" title="Recommended" type="button">
              {tests && <TestCard tests={tests} />}
              {(isLoadingRecommended ? <div className="m-3"><Loading /></div> : '')}

            
            </Tab>
            <Tab eventKey="Upcoming" title="Upcoming" type="button">
            {activeKey=== 'Upcoming' && 
            
            <TestCard tests={upcommingtests} />}
              {(isLoadingRecommended ? <div className="m-3"><Loading /></div> : '')}
</Tab>
            <Tab eventKey="Completed" title="Previous" type="button">
              {activeKey=== 'Completed'  && 
                <TestCard tests={completedtests} />}
             </Tab>
          </Tabs>

         
{pages>0 &&
                  <div className="fixed-bottom "> 
                      <ReactPaginate
                          previousLabel={'<<'}
                          nextLabel={'>>'}
                          pageCount={pages}
                          onPageChange={handlePageClick}
                          containerClassName={'pagination'}
                          activeClassName={'active'}
                      />
                  </div>

}
                  
        </div>
      </div>
    </div>
  );

}
export default VisionMdsDashboard