import React, { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap'
import 'react-phone-number-input/style.css'
import { Link } from 'react-router-dom'
import Card from '@mui/material/Card';

const TestCard = (props) => {
  const [tests, setTests] = useState([]);
  // const [testType, setTestType] = useState("");

  useEffect(() => {
    setTests(props['tests'])
    console.log('tests-->',props['tests'])
    // setTestType(props['type'])
  }, [props['tests']]);



  return (
    <Row className="mt-lg-3 mx-lg-0 mt-4 mx-0">

    {
    
    tests.length==0 ?  <div  className="row m-1 justify-content-center">No Tests Found</div> :
      tests.map((test , idx) => (
        <div key={idx} className="col-lg-6 col-12 pl-lg-4 pr-lg-4 mb-3">
          <Card elevation="6" sx={{ borderRadius: 5 }} key={test._id}>
            <div className="bg-dark p-lg-4 p-4">
              <div className="row">
                <div className="col-12">
                  <h6 className="text-white">{test.name}</h6>
                </div>
              </div>
              {test.scheduleDate && <div className="row align-items-center">
                <div className="col-lg-8 col-8">
                  <small className="text-white font-weight-light">
                    Date: {test.scheduleDate}
                  </small>
                </div>
                <div className="col-lg-4 text-right col-4">
                  <small className="text-danger">Active</small>
                </div>
              </div>}
            </div>

            <div className="card-bottom-border-radius bg-white"
              style={{ textAlign: 'center' }}>
              <div className="row mt-lg-4 mt-4 mx-0 pl-lg-4">
                {test['showAnswer'] && <div className="col-lg-6 col-6 pl-5 pl-lg-1">
                  <ul>
                    <li className="small-li">3 Synopsis</li>
                    <li className="small-li mt-lg-3">2 Videos</li>
                  </ul>
                </div>
                }
                <div className={"col-lg-6 col-6" + (!test['showAnswer'] ? ' pl-5 pl-lg-1' : '')}>
                  <ul>
                    <li className="small-li">{test.totalQuestionsCount} Questions</li>
                    <li className="small-li mt-lg-3">{test.duration} Minutes</li>
                  </ul>
                </div>
              </div>
              <div className="row mt-lg-1 mt-3 mb-3 mx-0 mb-lg-3 align-items-center">
                <div className="col-7">
                  {test['showAnswer'] && <a href style={{ color: '#0555E2' }}>
                    <u className="small-li" style={{ color: '#0555E2' }}>How to prepare?</u>
                  </a>}
                </div>
                <div className="col-5 p-0">
                <Link to={test['userTestStatus'] === 'resumeTest' ? `/Questions/${test._id}` : `/testInfo/${test._id}`}>
                              <button
                                type="button"
                                className="btn btn-danger border-0"
                                style={{ background: '#0555E2' }}
                              >
                                {{
                                  'retakeTest': 'Retake Test',
                                  'resumeTest': 'Resume Test',
                                  'takeTest': 'Take Test'
                                }[test['userTestStatus']]
                                }
                              </button>
                            </Link>
                </div>
              </div>
            </div>
          </Card>
        </div>
      ))

              }
        
    </Row>
  )
}

export default TestCard;