import { useEffect } from 'react'
import { setUserWithToken } from "../hooks/auth";
import { useSetExt } from '../hooks/query';

const useAppInitFetch = () => {

    const userDetials = setUserWithToken();
    useSetExt()
    useEffect(() => {

    }, [])

    return [userDetials];
}

export { useAppInitFetch };   