
import React from 'react'
import { useState, useEffect } from 'react'

const CustomVideo = (props) => {
    const [selectedVideoState, setSelectedVideoState] = useState({
        videoLinkObj: {},
        imageObj: {}
    });
    
    useEffect(() => {
        console.log("props.videoDetails =>", props.videoInfo)
        setSelectedVideoState(props.videoInfo);
        
    }, [props]);

    return (
    <div><video id="videoId" className="videodiv video_info w-100 p-3" key={selectedVideoState.videoLinkObj} src={selectedVideoState.videoLinkObj} webkit-playsinline playsinline 
        style={{sobjectPosition: 'center center', background: 'black'
        }} poster={selectedVideoState.imageObj} controls
        >
        <source src={selectedVideoState.videoLinkObj} type="video/mp4" />
    </video></div>)
}

export default CustomVideo;