import { useDispatch , useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { bijBolGet } from 'services/api';
import { parseJwt, getCookie } from 'utils/token';
import { tokenKeyName } from 'assets/data/constants';

const useLogout = () => {
    const  history = useHistory()
    const dispatch = useDispatch();
    return async () => {
        console.log("clicked on logout")
        const logOutRes = await bijBolGet('log_out');
        history.push("/login");
        dispatch({ type: "DELETE_PROFILE", value: {} });
        console.log(logOutRes);
        return;
    }
}

//If Access Token Exist then set user details to redux
const setUserWithToken = () => {
    const dispatch = useDispatch();
    const access_token = parseJwt(getCookie(tokenKeyName));
    if (access_token && access_token['user']) {
        console.log('accessToken', access_token)
        dispatch({ type: "SET_PROFILE", value: access_token.user });
        return access_token.user;
    }
    return {};
}

//If Access Token Exist then set user details to redux
const useIsAuthenticated = () => {
    const userSelector = useSelector((state) => state['Profile']);
    if(userSelector && userSelector['isAuthenticated']){
        return true
    }
    return false
}



export {useLogout, setUserWithToken, useIsAuthenticated};   