const initialState = {isAuthenticated : false};

export default function ProfileReducer(state = initialState, action) {
    switch (action.type) {
        case "SET_PROFILE":
            return {
                ...state, ...action['value'], ...{isAuthenticated : true}
            };
        case "GET_PROFILE":
            return state;
        case "DELETE_PROFILE":
            return {isAuthenticated : false}
        default:
            return state;
    }
}