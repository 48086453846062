import React from 'react'
import { useState, useEffect } from 'react'
import Loading from 'components/Loading';
import ModalPdfViewer from 'components/ModalPdfViewer';

const AnswerKey = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [pdfDetails, setPdfDetails] = useState([]);
    const [pdfUrl, setPdfUrl] = useState("");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        console.log("props.pdfDetails =>", props.pdfDetails)
        setPdfDetails(props.pdfDetails);
        setIsLoading(false)
    }, [props.pdfDetails]);

    const handleClickOpen = (url) => {
        setPdfUrl(url)
        setOpen(true);
    };

    return <div>
        {isLoading ? <div className='fullscreen'><Loading /></div> :
            <div style={{ color: 'black' }}>
                {pdfDetails.map((pdf, idx) => {
                    return <div key={idx} className="row m-1 pr-3">
                        <div className="col-lg-4 col-9 mt-0">
                            <div>
                                <small style={{ fontWeight: '500' }}>
                                    <i className="fa fa-file-text-o" aria-hidden="true"></i>
                                    <span className="ml-lg-2 ml-2">
                                        {pdf['name']}
                                    </span>
                                </small>
                            </div>
                        </div>
                        <div className="col-lg-8 text-lg-left col-3 text-right">
                            <div className="icon">
                                <button
                                    className="ml-lg-5 font-weight-bold bg-transparent small border-0"
                                    style={{ color: '#0555E2' }}
                                    onClick={() => handleClickOpen(pdf['key'])}
                                >
                                    Open
                                </button>
                            </div>
                        </div>
                    </div>
                })}
            </div>}
        {/* <Dialog
            fullScreen={fullScreen}
            open={open}
            style={{minWidth:'80%'}}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <Fab 
          style={{'top': '1%',left:'2%',
          'position': 'absolute'}}
          size="medium" onClick={() => handleClose()}>
            
        <CloseSharp />
      </Fab>
            <Pdfviewer url={pdfUrl} />
        </Dialog> */}
         {/* {Modal} */}
         <ModalPdfViewer open={open} onCloseModal={(e) => setOpen(e)} pdfUrl={pdfUrl}/>
    </div>
}

export default AnswerKey;