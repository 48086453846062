/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Container, Row } from 'react-bootstrap';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.scss';
import Tutorials from 'components/Tutorials';
import Livetests from 'components/Livetests';
import Recommendedvideos from 'components/Recommendedvideos';
import Quickaccess from 'components/Quickaccess';
import Activity from 'components/Activity';
// import Visionmds from 'pages/Visionmds';
SwiperCore.use([Navigation])


const Home = () => {
  const [liveTestData, setLiveTestData] = useState([]);
  const onLiveDataFetch = (data) =>{
    console.log('onLiveDataFetch =>', data);
    setLiveTestData(data)
  };

  return (
    <Container fluid>

     
      {/* <Row className="mt-lg-4 mx-lg-3 mt-4"> */}
        
        {/* <Swiper
          spaceBetween={50}
          slidesPerView={3}
          navigation
          breakpoints={{
            320: {
              width: 320,
              slidesPerView: 1,
            },
            768: {
              width: 768,
              slidesPerView: 2,
            },
            1024: {
              width: 500,
              slidesPerView: 1,
            }
          }}
        >
        </Swiper> */}
        <Livetests onDataFetch={onLiveDataFetch}></Livetests>
       
      {/* <Visionmds/> */}
      {/* </Row> */}
      <Row className="mt-lg-5 mx-lg-3 align-items-baseline mt-4">
        <div className="col-lg-10 col-8 mt-3 mt-lg-0">
          <h6 className="font-weight-bold">Schools </h6>
        </div>
        <div className="col-lg-2 col-4 mt-3 text-right">
          {/* <a style={{ color: '#0555E2' }}>View All</a> */}
        </div>
      </Row>
        <Tutorials></Tutorials>
        <Row className="mt-lg-5 mx-lg-3 align-items-baseline mt-4">
        <div className="col-lg-10 col-8">
          <h6 className="font-weight-bold">Recommended Videos</h6>
        </div>
        <div className="col-lg-2 col-4 mt-3 text-right">
          <a href style={{ color: '#0555E2' }}>View All</a>
        </div>
      </Row>
      <Recommendedvideos></Recommendedvideos>
      <Row className="mt-lg-5 mx-lg-3 align-items-baseline mt-4">
        <div className="col-lg-10 col-8">
          <h6 className="font-weight-bold">Quick Access</h6>
        </div>
        <div className="col-lg-2 col-4 mt-3 text-right">
          <a href style={{ color: '#0555E2' }}>View All</a>
        </div>
      </Row>
      <Quickaccess></Quickaccess>
      <Row className="mt-lg-5 mx-lg-3 align-items-baseline mt-4">
        <div className="col-lg-10 col-8">
          <h6 className="font-weight-bold">Activity</h6>
        </div>
        <div className="col-lg-2 col-4 mt-3 text-right">
          {/* <a style={{ color: '#0555E2' }}>View All</a> */}
        </div>
      </Row>
      <Activity></Activity>
    </Container>
  )
}

export default Home

