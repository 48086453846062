class AuthService {
  login(userId) {
    localStorage.setItem("user", JSON.stringify(userId));
  }
  logout() {
    localStorage.removeItem("user");
  }
  getCurrentUser() {
    var ussetapi=localStorage.getItem('user')
    if(ussetapi === undefined){
        console.log(ussetapi)
        return '';//JSON.parse(ussetapi);
    }else{
        return ussetapi;

    }
    
  }
}

export default new AuthService();
