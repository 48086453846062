import React from 'react'
import { Row, Container, Tabs } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import{ useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
// import { Link } from 'react-router-dom'

const ProfileDashboard = () => {
    const [value, onChange] = useState(new Date());
    return (
      <div>
        <Container fluid>
          <Row className="mx-lg-3 mt-lg-5 mb-lg-5 mt-5">
            <div className="col-lg-9 col-12">
              <div className="card border-0 shadow ">
                <div className="bg-dark p-lg-4 card-border-radius p-4">
                  <Row className="align-items-center">
                    <div className="col-lg-10 col-9">
                      <Row className="align-items-center">
                        <div className="col-lg-3 col-4">
                          <img
                            alt=''
                            src="images/doctor.jpg"
                            className="img-fluid"
                            style={{ borderRadius: '10px' }}
                          />
                        </div>
                        <div className="col-lg-9 col-8">
                          <h6 className="font-weight-bold text-white">
                            Dr. John Doe
                          </h6>
                          <div>
                            <small className="text-white">+91 976543210</small>
                          </div>
                          <div>
                            <small className="text-white">
                              JohnDoe@email.com
                            </small>
                          </div>
                        </div>
                      </Row>
                    </div>
                    <div className="col-lg-2 col-3 text-center">
                      <h3
                        style={{ color: '#0555E2' }}
                        className="font-weight-bold"
                      >
                        235
                      </h3>
                      <div>
                        <small className="text-white">All India Rank</small>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
              <div className="bg-white shadow card-bottom-border-radius pb-lg-4 pb-4">
                <Row
                  className="ml-2 mr-2 mx-0 mx-lg-2 pb-4 pt-4 pt-lg-5 row"
                  style={{ borderRadius: '20px' }}
                >
                  <div className="col-lg-3 text-center col-6">
                    <div>
                      <h3
                        className="font-weight-bold"
                        style={{ color: '#0555E2' }}
                      >
                        150
                      </h3>
                    </div>
                    <div>
                      <small>No of Q/A's Answered</small>
                    </div>
                  </div>
                  <div className="col-lg-3 text-center col-6">
                    <div>
                      <h3
                        className="font-weight-bold"
                        style={{ color: '#0555E2' }}
                      >
                        80
                      </h3>
                    </div>
                    <div>
                      <small>No. of Practice Tests Attempted</small>
                    </div>
                  </div>
                  <div className="col-lg-3 text-center col-6 mt-4 mt-lg-0 mt-md-0">
                    <div>
                      <h3
                        className="font-weight-bold"
                        style={{ color: '#0555E2' }}
                      >
                        30
                      </h3>
                    </div>
                    <div>
                      <small>No. of Daily Tests Attempted</small>
                    </div>
                  </div>
                  <div className="col-lg-3 text-center col-6 mt-4 mt-lg-0 mt-md-0">
                    <div>
                      <h3
                        className="font-weight-bold"
                        style={{ color: '#0555E2' }}
                      >
                        40
                      </h3>
                    </div>
                    <div>
                      <small>No.of Grand Tests Attempted</small>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
            <div className="col-12 col-lg-3 mt-4 mt-lg-0">
            <Calendar className="w-100"
        onChange={onChange}
        value={value}
      />
            </div>
          </Row>
          <Row className="mx-lg-3 mt-lg-5 mb-lg-5 mt-5">
            <div className="col-lg-12 col-12 tab-optimized">
              <Tabs
                defaultActiveKey="Recommended"
                transition={false}
                id="noanim-tab-example"
                className="mb-3"
              >
                <Tab eventKey="Recommended" title="Recommended">
                  <div>
                    <p className="text-center">Recommended </p>
                  </div>
                </Tab>
                <Tab eventKey="Upcoming" title="Upcoming">
                  <div>
                    <p className="text-center">Upcoming </p>
                  </div>
                </Tab>
                <Tab eventKey="Previous" title="Previous">
                  <div>
                    <p className="text-center">Previous</p>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </Row>
        </Container>
      </div>
    )
}

export default ProfileDashboard