/* eslint-disable no-undef */
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// const  Home =lazy(() => import('./pages/Home'));
import Home from "./pages/Home";
import Instructions from "components/Instructions";
import ScrollToTop from "components/scrolltotop";
import Header from "components/Header";
import Questions from "pages/Questions";
import Scorecard from "pages/Scorecard";
import Answerskey from "pages/Answerskey";
import Subscription from "pages/Subscription";
import VisionmdsTests from "pages/VisionmdsTests";
import VisionMdsDashboard from "pages/VisionMdsDashboard";
import TutorialsTests from "pages/TutorialsTests";
import Login from "pages/Login";
import Signup from "pages/Signup";
import ProfileDashboard from "pages/ProfileDashboard";
import TutorialsTopics from "pages/TutorialsTopics";
import Survey from "pages/Survey";
import ScheduledTests from "pages/ScheduledTests";
import Error from "pages/Error";
import Search from "pages/Search";
import PaymentHistory from "pages/PaymentHistory";
import {useAppInitFetch} from "./hooks/appLoad";

const App = () => {

  // setUserWithToken();
  useAppInitFetch();

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <ScrollToTop />
        <Switch>
          <Route path="/" exact render={() => {
                      return <Redirect to="/home" />
                }} />
          <Route path="/home" exact component={Home} />
          <Route path="/testinfo/:testid" exact component={Instructions} />
          <Route path="/Questions/:Questionid" exact component={Questions} />
          <Route path="/search" exact component={Search} />
          <Route
            path="/VisionmdsTests/:VisionmdsTestsid"
            exact
            component={VisionmdsTests}
          />
          <Route
            path="/TutorialsTests/:TutorialsTestsid"
            exact
            component={TutorialsTests}
          />
          <Route
            path="/Scorecard/:testId/:courseId"
            exact
            component={Scorecard}
          />
          <Route path="/Answerskey/:testId" exact component={Answerskey} />
          <Route path="/Subscription/:id?/:type?" exact component={Subscription} />
          <Route path="/Signup" exact component={Signup} />
          <Route path="/Login" exact component={Login} />
          <Route path="/ProfileDashboard" exact component={ProfileDashboard} />
          <Route path="/TutorialsTopics/:topicId/:subjectId" exact component={TutorialsTopics} />
          <Route path="/Survey" exact component={Survey} />
          <Route path="/ScheduledTests" exact component={ScheduledTests} />
          <Route path="/VisionMdsDashboard/:VisionmdsTestsid" exact component={VisionMdsDashboard} />
          <Route path="/PaymentHistory" exact component={PaymentHistory} />
          <Route path="/Error" exact component={Error} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
