import React, { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap';
import { bijBolPost } from 'services/api';
import moment from 'moment';
import PaymentFailedLogo from 'assets/images/payment_failed.png';
import PaymentPremiumLogo from 'assets/images/payment_premium.png';

import {
    Card, Typography,
    // CardContent,
    CardMedia, Box
} from '@mui/material';

const PaymentHistory = (props) => {
    console.log(props)
    const [paymentHistory, setPaymentHistory] = useState([]);

    const paymentSuccessRender = (val) => {
        return <><Typography className="mr-3" style={{ fontSize: '12px' }} variant="div" color="text.secondary" component="small">
            Ref id {val['orderId']}
        </Typography>
            <Typography style={{ fontSize: '12px' }} variant="div" color="red" component="div">
                Valid upto {moment(val['endDate']).format('DD/MM/YYYY')}
            </Typography></>
    }

    const paymentFailRender = () => {
        return <><Typography className="mr-3" style={{ fontSize: '12px' }} variant="div" color="red" component="small">
            Payment Failed
        </Typography></>
    }

    useEffect(async () => {
        const params = { "skip": 0, "limit": 10 }
        let paymentRes = await bijBolPost('getPaymentHistory', params);

        console.log('paymentRes', paymentRes.data.data)
        setPaymentHistory(paymentRes.data.data)
    }, []);

    return (<div>
        <Row className="m-3 mx-lg-5 mb-lg-7 mb-5">
            {paymentHistory.map((val, idx) => {
                return (<Card className="mt-3 mx-2 mx-lg-4 my-lg-3" key={idx} sx={{ display: 'flex', alignItems: 'center', borderRadius: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                        <CardMedia
                            style={{ 'textAlign': 'center' }}
                            component="img"
                            sx={{ width: 50, height: 50, }}
                            image={val['paymentStatus'] === 'completed' ? PaymentPremiumLogo : PaymentFailedLogo}
                            alt="Live from space album cover"
                        />
                    </Box>
                    <div className="m-1 ml-2" >
                        <Typography className="mr-3" style={{ fontSize: '15px' }} component="div" variant="h6">
                            {val['name']}
                        </Typography>
                        {val['paymentStatus'] === 'completed' ? paymentSuccessRender(val) : paymentFailRender()}
                    </div>
                    <div className="p-2" 
                    style={{ flexDirection: 'column',minWidth: '100px' ,borderRadius: 10, backgroundColor: '#f23232', height: '100%', color: 'white', display: 'flex',alignItems: 'center',justifyContent: 'center'}} >
                        <Typography className="p-2"  variant="div" component="h5">
                            {val['price']}
                        </Typography>
                        <Typography className="p-2"  style={{  fontSize: '8px'}}
                         variant="small" component="small">
                            {moment(val['createDate']).format('LL')}
                        </Typography>
                    </div>
                </Card>)
            })}
        </Row>
    </div>)
}

export default PaymentHistory;