import React, { useState } from 'react'
import { Container, Row, Card } from 'react-bootstrap'
import Bannerimg1 from 'assets/images/stemlabicon.png';
import 'react-phone-number-input/style.css'
import AuthService from '../services/auth.service';
import { bijBolPost } from 'services/api';
import { useDispatch } from 'react-redux'

const Login = (props) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    id: '',
    firstName: '',
    lastName: '',
    emailId: '',
    phoneNumber: '',
    countryCode: '91',
    showme: true,
    showdivme: false,
    otp: ''
  })
  const [showOtpRender, setShowOtpRender] = useState(false)
  const [disableSendOtp, setDisableSendOtp] = useState(true)
  const [mobileInputErr, setMobileInputErr] = useState("")
  const [disableVerifyOtp, setDisableVerifyOtp] = useState(true)
  const [otpInputErr, setOtpInputErr] = useState("")

  const saveOrUpdateEmployee = async (e) => {
    e.preventDefault()
    let userNumber = { phoneNumber: formData.phoneNumber }
    let countryCode = { countryCode: formData.countryCode }
    let finalNumber = formData.countryCode + formData.phoneNumber
    console.log(userNumber, countryCode, finalNumber)
    
    const otpRes = await bijBolPost('otpSend', {
      phoneNum: finalNumber,
    })
    console.log('otpRes',otpRes)
    if(otpRes && otpRes['data'] && otpRes['data']['status']) setShowOtpRender(true);

    console.log('finalNumber', finalNumber)
    console.log('userNumber => ' + JSON.stringify(userNumber))
    console.log('countryCode => ' + JSON.stringify(countryCode))
  }


  const verifyOtp = async (e) => {
    e.preventDefault()
    let finalNumber = formData.countryCode + formData.phoneNumber;
    let otp = formData.otp;
    const webUserLoginRes = await bijBolPost('webUserLogin', { phoneNum: finalNumber, otp: otp });
    console.log('webUserLoginRes =>', webUserLoginRes)
    if (webUserLoginRes && webUserLoginRes['data']['status']) {
      dispatch({ type: "SET_PROFILE", value: webUserLoginRes.data.data });
      AuthService.login(webUserLoginRes.data.data._id)
      props.history.push('/home')
    } else{
      setOtpInputErr('Please Enter valid Otp')
    }

    console.log('finalNumber ', finalNumber)
    console.log('otp ', otp)
  }

  const changeLastNameHandler = (event) => {
    const isValid = validationMobile(event.target.value)
    if(isValid) setFormData({ ...formData, phoneNumber: event.target.value });
  }
  const changecountryCode = (event) => {
    setFormData({ ...formData, countryCode: event.target.value })
  }

  const changeOtpeHandler = (event) => {
    const isValid = validationOtp( event.target.value);
    if(isValid) setFormData({ ...formData, otp: event.target.value });
  }

  const validationMobile = (mobileNumber) => {
    let pattern = new RegExp(/^[0-9\b]+$/);

    setDisableSendOtp(true)
    if (!mobileNumber) {
      // setMobileInputErr("Please enter mobile number.")
      return true;
    } else if (mobileNumber.length > 10) {
      // setMobileInputErr("Please enter valid phone number.")
      setDisableSendOtp(false)
      return false;
    }
    else if (!pattern.test(mobileNumber)) {
      // setMobileInputErr("Please enter only number.")
      return false;

    } else if(mobileNumber.length <= 10) {
      setMobileInputErr("")
      if(mobileNumber.length === 10) setDisableSendOtp(false);
        return true;
    }  else {
      setMobileInputErr("")
      setDisableSendOtp(false)
      return true;
    }
  }

  const validationOtp = (otp) => {
    console.log('otp', otp)

    let pattern = new RegExp(/^[0-9\b]+$/);

    setDisableVerifyOtp(true)
    if (!otp) {
      // setOtpInputErr("Please enter mobile number.")
      return true;
    } else if (otp.length > 4) {
      // setOtpInputErr("Please enter valid phone number.")
      setDisableVerifyOtp(false)
      return false;
    }
    else if (!pattern.test(otp)) {
      // setOtpInputErr("Please enter only number.")
      return false;

    } else if(otp.length <= 4) {
      setOtpInputErr("")
      if(otp.length === 4) setDisableVerifyOtp(false);
        return true;
    }  else {
      setOtpInputErr("")
      setDisableVerifyOtp(false)
      return true;
    }
  }

  const enterMobileRender = () => {
    return (<div className="col-lg-6" id="phoneNumber">
      <div>
        <h4 className="text-center my-2">Sign In</h4>
      </div>
      <div className="text-center">
        <h6 className="text-center text-color-pink font-weight-bold">
          Welcome
        </h6>
        <small className="text-center text-color-pink">
          {mobileInputErr}
        </small>
      </div>
      <div className="text-center mt-3">
        <Row className="mx-0">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <form >
              <Row className="align-items-center row">
                <div className="col-lg-4 col-md-4 col-3">
                  <select
                    name="countryCode"
                    style={{ padding: '0' }}
                    value={formData.countryCode}
                    className="form-control form_control"
                    onInput={(e) => changecountryCode(e)}
                  >
                    <option>select</option>
                    <option value="91" selected>+91</option>
                    <option value="1">+1</option>
                    <option value="61">+61</option>
                  </select>
                </div>
                <div className="col-lg-8 col-md-8 col-9">
                  <input
                    type="text"
                    placeholder="Phone Number"
                    style={{ background: '#f6f6f6' }}
                    name="phoneNumber"
                    
                    value={formData.phoneNumber}
                    className="form-control form_control"
                    onChange={(e) => changeLastNameHandler(e)}
                  />
                </div>
              </Row>
              <div className="my-3">
                <button
                  type="submit"
                  disabled={disableSendOtp}
                  onClick={(e) => saveOrUpdateEmployee(e)}
                  className="btn btn-block text-white"
                  style={{ background: '#0555E2' }}
                >Send Otp
                </button>
              </div>
            </form>
          </div>
          <div className="col-lg-2"></div>
        </Row>
      </div>
    </div>)
  }

  const sendOtpRender = () => {
    return (<div className="col-lg-6" id="otp">
      <div>
        <h4 className="text-center">Sign In</h4>
      </div>
      <div className="text-center mt-3">
        <h6 className="text-center text-color-pink font-weight-bold">
          Verification
        </h6>
        <small className="text-center text-color-pink">
          OTP has been sent to your mobile number
        </small>
      </div>
      <form>
        <Row className="justify-content-center row mt-3">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 col-md-8 col-7">
            <input
              type="text"
              placeholder="Enter OTP"
              style={{ background: '#f6f6f6' }}
              name="otp"
              className="form-control form_control"
              value={formData.otp}
              onChange={(e) => changeOtpeHandler(e)}
            />
          </div>
          <div className="col-lg-2"></div>
        </Row>
        <div className="row justify-content-center"> 
        {/* <small className="text-center text-color-pink">
          {showInvalidOtp && 'Please add valid otp'}
          </small> */}
          <small className="text-center text-color-pink">
          {otpInputErr}
          </small>
          </div>
        <Row>
          <div className="col-lg-2"></div>
          <div className="col-lg-7 m-3 text-center">
            <button
              type="submit"
              disabled={disableVerifyOtp}
              onClick={(e) => verifyOtp(e)}
              className="btn btn-block text-white"
              style={{ background: '#0555E2' }}
            >
              Submit
            </button>
            <div className="p-2">
              <small onClick={(e) => saveOrUpdateEmployee(e)} className="mr-4  clicable text-center text-color-pink">
                Resend
              </small>

              <small onClick={() => setShowOtpRender(false)} className="ml-4 clicable text-center text-color-pink">
                Back
              </small>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </Row>
      </form>
    </div>)
  }
  //-{"phoneNum":"917396814553","otp":"4035"}
  // render() {
  return (
    <div>
      <Container fluid>
        <Row className="mt-lg-3 mx-lg-0 mt-4 mx-0 pb-2">
          <div className="col-lg-2"></div>

          <div className="col-lg-8">
            <Card
              className="shadow border-0"
              style={{ borderRadius: '15px' }}
            >
              <Row className="align-items-center">
              <div className="col-lg-6 logo-div">

<img alt='' src={Bannerimg1} className="img-fluid ml-4" />

<div className="ml-4" style={{fontFamily: 800}}>

<h5 className="login-h5">(STEM) Education, <br></br>



Network  Market place </h5>

<p className="" style={{fontSize: '12px'}}>Join the  Networking Community</p>

</div>

</div>
                {showOtpRender ? sendOtpRender() : enterMobileRender()}
              </Row>
            </Card>
          </div>
          <div className="col-lg-2"></div>
        </Row>
      </Container>
    </div>
  )
}

export default Login;
