import React from 'react';
import { Row } from 'react-bootstrap';

const Activity = () => {
        return (
            <div>
                <Row className="mx-lg-3 mt-lg-3 mt-3 mb-5">
                    <div className="col-lg-4 col-12">
                        <div className="card border-0 shadow" style={{borderRadius: '10px'}}>
                            <div className="align-items-center mx-0 pt-3 pb-3 row">
                                <div className="col-lg-3 text-center  col-3">
                                    <img alt='' src="images/teeth4.jpg" style={{height: '70px', width: '70px', borderRadius: '50%'}}/>
                                </div>
                                <div className="col-lg-8 col-9">
                                    <h6>3/5 Tests to attend</h6>
                                    <p className="text-muted">Complete your daily test</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 mt-3 mt-lg-0 mt-md-0">
                        <div className="card border-0 shadow" style={{borderRadius: '10px'}}>
                            <div className="align-items-center mx-0 pt-3 pb-3 row">
                                <div className="col-lg-3 text-center  col-3">
                                    <img alt='' src="images/teeth4.jpg" style={{height: '70px', width: '70px', borderRadius: '50%'}}/>
                                </div>
                                <div className="col-lg-8 col-9">
                                    <h6>Analytical Epidemology</h6>
                                    <p className="text-muted">Complete your daily test</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 mt-3 mt-lg-0 mt-md-0">
                        <div className="card border-0 shadow" style={{borderRadius: '10px'}}>
                            <div className="align-items-center mx-0 pt-3 pb-3 row">
                                <div className="col-lg-3 text-center  col-3">
                                    <img alt='' src="images/teeth4.jpg" style={{height: '70px', width: '70px', borderRadius: '50%'}}/>
                                </div>
                                <div className="col-lg-8 col-9">
                                    <h6>3/5 Tests to attend</h6>
                                    <p className="text-muted">Complete your daily test</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        )
}

export default Activity;
