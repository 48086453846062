import React from 'react'
import { useState, useEffect } from 'react'
import {  Row } from 'react-bootstrap'

const QuestionSelection = (props) => {
    const [question, setQuestion] = useState({
        options: [],
        optionSet: '',
        questionNumber: '0',
        _id: '',
        value: '',
        question: {
            value: ''
        }
    })
    const [isSelectableOption, setIsSelectableOption] = useState(false)
    const [questionNumber, setQuestionNumber] = useState(0)
    const [selectedSet, setSelectedSet] = useState('')
    const [showCorrectAns, setShowCorrectAns] = useState(false)
    const [showAnsOnlyAfterClick, setShowAnsOnlyAfterClick] = useState(false)

    useEffect(() => {
        if (props['selectedOption']) props.selectedOption(props['selectedOptionByUser']);
        console.log("props inside question selection",props)
        setSelectedSet(props['selectedOptionByUser'])
        setQuestion(props['question'])

        setIsSelectableOption(props['isSelectable']);
        setQuestionNumber(props['questionNumber']);
        setShowCorrectAns(props['showCorrectAns']);
        setShowAnsOnlyAfterClick(props['showAnsOnlyAfterClick'])
        if(props['showAnsOnlyAfterClick'] && props['selectedOptionByUser']) setShowCorrectAns(true);

        if(props['reset']) console.log("On Reset");
        

    }, [props['question'],props['selectedOptionByUser'],props['reset']]);

    const selectedOption = (optionSet) => {
        if (isSelectableOption) {
            props.selectedOption(optionSet);
            setSelectedSet(optionSet)
        }
        console.log('showAnsOnlyAfterClick',showAnsOnlyAfterClick)
        if(showAnsOnlyAfterClick) setShowCorrectAns(true);
    }

    return <div >
        <Row className="pt-lg-4 mx-0 mx-lg-2 pt-3" style={{color:'black'}}>
            <div className="col-lg-12 question-text-mobile">
                <p>
                    {questionNumber}) {question['question'] && question['question']['value']}
                </p>
                {question['question']['imagePath'] && <img alt='' style={{ width: '40%' }} src={question['question']['imagePath']} />}
            </div>
        </Row>
        <Row className="pt-lg-4 mx-0 mx-lg-2 question-selection-mobile">
            {question['options'] && question['options'].map((option, index) => (
                <div key={index} className="col-lg-6 col-6 p-1">
                    {<p className={`options ${option['optionSet'] === selectedSet ? 'selected' : null} ${showCorrectAns && option['optionSet'] === question['correctAnswerIds'][0] ? 'correct-answer' : null}`}
                        style={{
                            borderRadius: '10px',
                            border: '1px solid #000',
                            padding: '10px',
                        }}
                        key={option._id}
                        onClick={() => selectedOption(option['optionSet'])}>
                        <div style={{wordWrap: "break-word"}}>{option.optionSet}. {option.value}</div>
                        {/* <img style={{ width: '100%' }} src={option['imagePath']} /> */}
                    </p>}
                </div>
            ))}
        </Row></div>

}

export default QuestionSelection;