import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import Fab from '@mui/material/Fab';
import Pdfviewer from 'components/Pdfviewer'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseSharp from '@mui/icons-material/CloseSharp';

const ModalPdfViewer = (props) => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const [pdfUrl, setPdfUrl] = useState("");
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        setPdfUrl(props['pdfUrl']);
        setOpen(props['open']);
    }, [props])

    const handleClose = () => {
        console.log("close clicked")
        setOpen(false);
        props.onCloseModal(false)
    };

    return (<Dialog
        fullScreen={fullScreen}
        open={open}
        style={{ minWidth: '80%' }}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title">
        <Fab
            style={{
                'top': '1%', left: '2%', 'z-index': '1',
                'position': 'absolute'
            }}
            size="medium" onClick={() => handleClose()}>
            <CloseSharp />
        </Fab>
        <Pdfviewer url={pdfUrl} />
    </Dialog>)
}

export default ModalPdfViewer