import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { getCookie, parseJwt } from "../utils/token";
import { tokenKeyName } from 'assets/data/constants';
import { setCookieToken } from "../utils/token";

export default function ScrollToTop() {
  const { pathname, search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  //Set Cokkie if search has token
  if (search) {
    console.log(search)
    try {
      let access_token;
      const params = new URLSearchParams(search);

      console.log('params =>', params.get(tokenKeyName))
      console.log('tokenKeyName =>', params.get(tokenKeyName))

      if(params.get(tokenKeyName)){
        access_token = params.get(tokenKeyName);
      }else{
        access_token = parseJwt(getCookie());
      }
      
      console.log("set Cookie if query with access token", access_token);
      if(access_token){
        setCookieToken(access_token)
        history.push(pathname)
      }
    } catch (e) {
      console.log("no token found on query string")
    }
  }

  return null;
}
