export const baseApiUrl = 'https://stemlabsapi.azurewebsites.net/api/v1/'
// export const baseApiUrl = 'http://localhost:8080/api/v1/'

export const sourceVideoUrl = {
  sintelTrailer: 'http://media.w3.org/2010/05/sintel/trailer.mp4',
  bunnyTrailer: 'http://media.w3.org/2010/05/bunny/trailer.mp4',
  bunnyMovie: 'http://media.w3.org/2010/05/bunny/movie.mp4',
  test: 'http://media.w3.org/2010/05/video/movie_300.webm'
};

export const testStatusList = [
  {
    style: {
      background: 'rgb(249, 0, 80)',
      height: '30px',
      width: '30px',
      borderRadius: '5px',
    },
    status: "Answered"
  },{
    style: {
      border: '3px solid black',
      height: '30px',
      width: '30px',
      borderRadius: '5px',
    },
    status: "Not Answered"
  }
]

export const testModalStatus = [
  {
    style: {
      background: 'rgb(249, 0, 80)',
      height: '30px',
      width: '30px',
      borderRadius: '5px',
    },
    status: "Answered"
  },{
    style: {
      border: '3px solid black',
      height: '30px',
      width: '30px',
      borderRadius: '5px',
    },
    status: "Not Answered"
  },
]

export const tokenKeyName = 'x_access_token';

export const testReview = [
  {
    style: {
      background: '#30d572',
      height: '30px',
      width: '30px',
      borderRadius: '5px',
    },
    status: "Correct"
  },
  {
    style: {
      background: 'rgb(249, 0, 80)',
      height: '30px',
      width: '30px',
      borderRadius: '5px',
    },
    status: "Incorrect"
  },
  {
    style: {
      border: '3px solid black',
      height: '30px',
      width: '30px',
      borderRadius: '5px',
    },
    status: "Not Answered"
  }
]

export const profileImgHost = 'https://djuny39y84drr.cloudfront.net/'
export const errorInvalidUserMessage = 'Invalid userId && should be Login';