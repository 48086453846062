import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import PlanInfo from 'components/PlanInfo';
import { displayRazorpay } from 'utils/payment';
import { readMoreText } from 'utils/text';
import Card from '@mui/material/Card';
import { bijBolPost } from 'services/api';
import moment from 'moment';
import { useSelector } from 'react-redux';

const Subscription = (props) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [individual, setIndividual] = useState({});
  const [subscriptionInfo, setSubscriptionInfo] = useState({});
  const [planInfo, setPlanInfo] = useState({});
  const [open, setOpen] = React.useState(false);

  const userSelector = useSelector((state) => state['Profile']);

  const [ subsLoaded, setSubsLoaded] = useState(false);

  useEffect(() => {
    console.warn(userSelector);

    if (!userSelector.isAuthenticated ) {
      props.history.push('/login');
    }

    let subscritionReq = {
      type: props.match.params['type'],
      id: props.match.params['id'],
    };
    if (!props.match.params['type']) subscritionReq = { skip: 0, limit: 10 };

    bijBolPost('getSeletedSubscriptions', subscritionReq)
      .then((result) => {
        console.log('result subscription ===>', result);
        if (result['data']['data']['subscriptionPlans'])
          setSubscriptions(result['data']['data']['subscriptionPlans']);
        if (result['data']['data']['individual'])
          setIndividual(result['data']['data']['individual']);
        if (result['data']['data']['individual'])
          setSubscriptionInfo(result['data']['data']['individual']);


          setSubsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props]);

  const planInfoDetails = async (planInfo) => {
    setOpen(true);
    setPlanInfo(planInfo);
  };

  const onBuy = async (subscriptionInfo) => {
    console.log('subscriptionInfo =>', subscriptionInfo);
    const displayRazorpayRes = await displayRazorpay(
      subscriptionInfo,
      userSelector
    );
    console.log('orderSubscriptionRes =>', displayRazorpayRes);
  };

  return (
    <div>
      <Container fluid>
        <Row className='m-3 mx-lg-3 mt-lg-5 mb-lg-5 mt-5 mb-5 justify-content-center'>
          { console.warn("Loaded" + subsLoaded)}
          {
           
          subsLoaded && (
          Object.keys(individual).length > 0 ||
          (subscriptions && subscriptions.length > 0)) ? (
            Object.keys(individual).length > 0 && (
              <div className='col-lg-3 col-12 p-0 m-4'>
                <Card elevation='4' sx={{ borderRadius: 3 }}>
                  <div className='bg-dark p-lg-4 p-4 text-white'>
                    <Row className=''>
                      <div className='col-lg-9 col-8'>
                        <div> {individual['name']}</div>
                        <div className='mt-2 small'>Validity 1 year</div>
                      </div>
                      <div className='col-lg-3 text-center col-4 p-0'>
                        <h5 style={{ color: '#0555E2' }}>
                          {' '}
                          ₹ {individual['price']}
                        </h5>
                      </div>
                    </Row>
                  </div>
                  <div className='bg-white'>
                    <Row className='mt-lg-4 mt-4 mx-0 pl-lg-4'>
                      <div className='col-lg-12 col-12 pl-5 pl-lg-1 m-4'></div>
                    </Row>
                    <Row className='my-4 mx-2 justify-content-end'>
                      <div className='col-lg-5 pl-lg-0 col-4  text-right'>
                        <button
                          type='button'
                          onClick={() =>
                            onBuy({
                              id: individual['_id'],
                              finalPrice: individual['price'],
                            })
                          }
                          className='btn btn-danger border-0'
                          style={{ background: '#0555E2' }}
                        >
                          Buy Now
                        </button>
                      </div>
                    </Row>
                  </div>
                </Card>
              </div>
            )
          ) : (

            subsLoaded && (Object.keys(individual).length <= 0 ||
            (subscriptions && subscriptions.length <= 0)) &&
            <div>
              <p style={{ color: 'red' }}>There is No Tests Available</p>
            </div>
          )}
          {subscriptions.map((subscription, idx) => (
            <div key={idx} className='col-lg-3 col-12 p-0 m-4'>
              <Card elevation='4' sx={{ borderRadius: 3 }}>
                <div className='bg-dark p-lg-4 p-4 text-white'>
                  <Row className=''>
                    <div className='col-lg-9 col-8'>
                      <div>
                        {' '}
                        {subscriptionInfo['name']
                          ? subscriptionInfo['name']
                          : subscription['subScriptionPlanName']}
                      </div>
                      <div className='mt-2 small'>
                        Validity {moment(subscription['endDate']).format('ll')}
                      </div>
                    </div>
                    <div className='col-lg-3 text-center col-4 p-0'>
                      <h5 style={{ color: '#0555E2' }}>
                        {' '}
                        ₹ {subscription['finalPrice']}
                      </h5>
                      <div>
                        <strike>
                          <span> ₹ {subscription['actualPrice']}</span>
                        </strike>
                      </div>
                    </div>
                  </Row>
                </div>
                <div className='bg-white'>
                  <Row className='mt-lg-4 mt-4 mx-0 pl-lg-4'>
                    <div className='col-lg-12 col-12 pl-5 pl-lg-1'>
                      <ul>
                        <li>
                          {readMoreText(subscription['description'][0], 20)}
                        </li>
                      </ul>
                    </div>
                  </Row>
                  <Row className='my-4 mx-2 justify-content-end'>
                    <div className='col-lg-7 pl-lg-0 col-8'>
                      <u
                        style={{ color: '#0555E2', cursor: 'pointer' }}
                        onClick={() => planInfoDetails(subscription)}
                      >
                        What Will I get?
                      </u>
                    </div>
                    <div className='col-lg-5 pl-lg-0 col-4  text-right'>
                      <button
                        type='button'
                        onClick={() => onBuy(subscription)}
                        className='btn btn-danger border-0'
                        style={{ background: '#0555E2' }}
                      >
                        Buy Now
                      </button>
                    </div>
                  </Row>
                </div>
              </Card>
            </div>
          ))}
        </Row>
      </Container>
      {<PlanInfo open={open} info={planInfo} onClose={() => setOpen(false)} />}
    </div>
  );
};

export default Subscription;
