import React, { useState, useEffect } from 'react';
import { Row, Container } from 'react-bootstrap';
import AnswerReview from 'components/AnswerReview';
import AnswerKey from 'components/AnswerKey';
import ExplainerVideo from 'components/ExplainerVideo';
import { bijBolPost } from 'services/api';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { TabContext } from '@mui/lab';
import TabPanel from '@mui/lab/TabPanel';
import Tabs from '@mui/material/Tabs';
import { checkAttemptedQuestion } from 'utils/questions.js';

var mediaObj = {};
console.log('mediaObj----------------------', mediaObj);
const Answerskey = (props) => {
  const [videoDetails, setVideoDetails] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(true);
  const [pdfDetails, setPdfDetails] = useState([]);
  const [tab, setTab] = useState('explainer-video');
  const [testObj, setTestObj] = useState({});
  const [questions, setQuestions] = useState(false);
  const [attemptedquestions, setAttemptedQuestion] = useState('');
  const [attemptedQuestionMap, setAttemptedQuestionMap] = useState(new Map());
  const [testStartedDateTime, setTestStartedDateTime] = useState({
    date: '',
    time: '',
  });

  useEffect(() => {
    const attempt = props.history.location.state.data.attemptedQuestions;
    console.log(attempt, 'attempt');
    setAttemptedQuestion(attempt);
    console.log(attemptedquestions, 'data');
    console.log('this.props.match.params.testId', props.match.params.testId);

    let videoData = {
      type: 'video',
      testId: props.match.params.testId,
      skip: 0,
      limit: 10,
    };

    let pdfData = {
      type: 'pdf',
      testId: props.match.params.testId,
      skip: 0,
      limit: 10,
    };

    bijBolPost('getTestByKeys', videoData)
      .then((res) => {
        console.log('RequestObjet -------', res.data);
        setVideoDetails(res.data['data']);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    bijBolPost('getEntranceTestById', {
      testId: props.match.params.testId,
      onlyInfo: false,
    })
      .then(async (res) => {
        let questions = res.data.data['questions'];
        console.log('questions =in side answer Key>', res.data.data);
        // setTestStartedDateTime({ ...testStartedDateTime, date: res.data.data['scheduleDate'] });
        setTestObj(res.data.data);
        setQuestions(questions);
        // setAttemptedQuestion(new Array(questions.length).fill({})) // declaring the length of attempted and questions as same
        let checkAttemptedQuestionRes = await checkAttemptedQuestion(
          res.data.data
        );

        //Creating HasMap for attempted question
        if (
          checkAttemptedQuestionRes['questionInfo'] &&
          checkAttemptedQuestionRes['questionInfo'].length > 0
        ) {
          console.log('in if condition');
          let attemptedQuestionResult = new Map(
            checkAttemptedQuestionRes['questionInfo'].map((i) => [
              i.questionId,
              i.reviewObj,
            ])
          );
          console.log(attemptedQuestionResult, 'attemptedQuestionResult');
          setAttemptedQuestionMap(attemptedQuestionResult);
          setTestStartedDateTime({
            date: res.data.data['scheduleDate'],
            time: checkAttemptedQuestionRes['startTime'],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    bijBolPost('getTestByKeys', pdfData)
      .then((res) => {
        console.log('getTestByKeys PDF=>', res.data.data);
        setPdfDetails(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Container fluid='xl' className='answerkey'>
      <Row className='mt-lg-5 mb-5 mt-5'>
        <div className='col-lg-12 col-12'>
          <div className='card border-0 shadow '>
            <div className='card border-0 shadow '>
              <div className='bg-dark p-lg-4 card-border-radius p-3'>
                <Row className='align-items-center'>
                  <div className='col-lg-6 col-8'>
                    <p className='text-white mb-0'>{testObj.name}</p>
                    <small className='text-white font-weight-light'>
                      {testStartedDateTime['date']} |{' '}
                      {testStartedDateTime['time']}
                    </small>
                  </div>
                  <div className='col-lg-3 text-lg-center col-4 text-right'>
                    <div style={{ color: '#09dc09' }}>
                      Attempted Questions : {attemptedquestions}
                    </div>
                  </div>
                  <div className='col-lg-3 text-lg-center col-4 text-right'>
                    <div style={{ color: '#09dc09' }}>Active</div>
                  </div>
                </Row>
              </div>
            </div>
            <div className='bg-white shadow card-bottom-border-radius'>
              {/* <Tabs
                defaultActiveKey="answer-key"
                transition={false}
                id="noanim-tab-example"
                className="row col-12 m-lg-3 m-3"
              >
                <Tab eventKey="answer-review" title="Answer Review">
                  <AnswerReview questionId={props.match.params.testId} />
                </Tab>
                <Tab eventKey="explainer-video" title="Explainer Video">
                  {videoDetails.length > 0 && <ExplainerVideo videoDetails={videoDetails} />}
                </Tab>
                <Tab eventKey="answer-key" title="Answer Key">
                  {pdfDetails.length > 0 && <AnswerKey pdfDetails={pdfDetails} />}
                </Tab>
              </Tabs> */}
              <Box sx={{ width: '100%' }}>
                <TabContext value={tab}>
                  <Tabs
                    style={{ backgroundColor: '#343a40' }}
                    value={tab}
                    variant='fullWidth'
                    onChange={(e, v) => setTab(v)}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: '#0555E2',
                      },
                    }}
                    aria-label='secondary tabs example'
                  >
                    <Tab
                      disableRipple
                      style={{
                        fontSize: '80%',
                        padding: '5px',
                        color: tab === '2' ? '#0555E2' : 'white',
                      }}
                      value='explainer-video'
                      label='Explainer Video'
                    />
                    <Tab
                      disableRipple
                      style={{
                        fontSize: '80%',
                        padding: '5px',
                        color: tab === '3' ? '#0555E2' : 'white',
                      }}
                      value='answer-key'
                      label='Answer key'
                    />
                    <Tab
                      disableRipple
                      style={{
                        fontSize: '80%',
                        padding: '5px',
                        color: tab === '1' ? '#0555E2' : 'white',
                      }}
                      value='answer-review'
                      label='Answer Review'
                    />
                  </Tabs>
                  <TabPanel class='p-1' value='explainer-video'>
                    {videoDetails.length > 0 && (
                      <ExplainerVideo videoDetails={videoDetails} />
                    )}
                  </TabPanel>
                  <TabPanel class='p-1' value='answer-key'>
                    {pdfDetails.length > 0 && (
                      <AnswerKey pdfDetails={pdfDetails} />
                    )}
                  </TabPanel>
                  <TabPanel class='p-1' value='answer-review'>
                    {questions.length > 0 && (
                      <AnswerReview
                        testid={props.match.params.testId}
                        questions={questions}
                        attemptedQuestionMap={attemptedQuestionMap}
                      />
                    )}
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default Answerskey;
