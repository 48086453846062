import React from 'react'
import { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap'

const ExplainerVideo = (props) => {
    const [videoDetails, setVideoDetails] = useState([]);

    const [selectedVideoState, setSelectedVideoState] = useState({
        videoLinkObj: {},
        imageObj: {}
    })

    useEffect(() => {
        console.log("props.videoDetails =>",props.videoDetails)
        setVideoDetails(props.videoDetails);
        selectedVideo(props.videoDetails[0].key, props.videoDetails[0].thumbnailImage);
    }, [props.videoDetails])

    const selectedVideo = (filePath, thumbNailPath) => {
        console.log('item, abc', filePath, thumbNailPath)
        setSelectedVideoState({
            videoLinkObj: filePath,
            imageObj: thumbNailPath
        })
    }

    return <Row>
        <div className="col-lg-9 mt-lg-5">
            <video key={selectedVideoState.videoLinkObj} src={selectedVideoState.videoLinkObj} className="video_info w-100 p-3"
                poster={selectedVideoState.imageObj}
                autoPlay controls>
                <source src={selectedVideoState.videoLinkObj} type="video/mp4" />
            </video>
        </div>
        <div className="col-lg-3 my-4 mt-lg-0">
            <h6 className="col" >Similar Videos</h6>
            <Row className="col mx-0 mt-3 mt-lg-0 cursor-pointer">
                {videoDetails.map((vds, idx) => (
                    <div key={idx} className="col-lg-12 col-6 p-0  my-4">
                        <div className="video-box">
                            <a href onClick={() => selectedVideo(vds.key, vds.thumbnailImage)}>
                                <img alt=''
                                    src={vds.thumbnailImage}
                                    className="img-fluid"
                                    style={{ borderRadius: '10px' }}
                                />
                                {/* </Link> */}
                                <div className="play-btn">
                                    <i className="fa fa-play"></i>
                                </div>
                            </a>
                        </div>
                    </div>
                ))}
            </Row>
        </div>
    </Row>

}

export default ExplainerVideo