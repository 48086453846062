import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import moment from 'moment';

const PlanInfo = (props) => {
    const [info, setInfo] = useState({});
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setInfo(props['info']);
        console.log('props["info"]', props['info'])
        setOpen(props['open']);
    }, [props])

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}>
            <DialogTitle sx={{ m: 0, p: 2 }}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent class="m-3">
                <div>
                    <b>Description</b>
                    <ul className="m-3">
                        {info['description'] && info['description'].map((a, idx) => (
                            <li key={idx} className="mt-lg-3"> {a} </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <div><b>Price:</b> {info['finalPrice']} Rs</div>
                    <div><b>Actual Price:</b> {info['actualPrice']} Rs</div>
                    <div><b>Validity Date:</b> {moment(info['endDate']).format('ll')}</div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default PlanInfo;
