import React from 'react';
import { useState, useEffect } from 'react';
import { bijBolPost } from 'services/api';
import { Row } from 'react-bootstrap';
import Loading from 'components/Loading';
import QuestionSelection from 'components/QuestionSelection';
import { checkAttemptedQuestion } from 'utils/questions.js';
import { testReview } from 'assets/data/constants';
const AnswerReview = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [attemptedQuestionMap, setAttemptedQuestionMap] = useState(new Map());

  useEffect(async () => {
    // console.log(props, 'props');
    // setIsLoading(true);
    let datas = { testId: props.testid, onlyInfo: false };
    const questionRes = await bijBolPost('getEntranceTestById', datas);
    console.log(questionRes, 'questionRes');
    settingAttemptedQuestion(questionRes.data.data);
    setQuestions(props.questions);
    // console.log(props.attemptedQuestionMap, 'props.attemptedQuestionMap');
    // setAttemptedQuestionMap(props.attemptedQuestionMap);
    // console.log("props Answer Review =>", props)
    setIsLoading(false);
  }, [props.questions, props.attemptedQuestionMap]);
  const settingAttemptedQuestion = async (data) => {
    let checkAttemptedQuestionRes = {};
    // setAttemptedQuestion(new Array(questions.length).fill({})) // declaring the length of attempted and questions as same
    console.log(data, 'data');
    checkAttemptedQuestionRes = await checkAttemptedQuestion(data);
    console.log(checkAttemptedQuestionRes, 'checkAttemptedQuestionRes');
    //Creating HasMap for attempted question
    if (
      checkAttemptedQuestionRes['questionInfo'] &&
      checkAttemptedQuestionRes['questionInfo'].length > 0
    ) {
      let attemptedQuestionResult = new Map(
        checkAttemptedQuestionRes['questionInfo'].map((i) => [
          i.questionId,
          i.reviewObj,
        ])
      );
      setAttemptedQuestionMap(attemptedQuestionResult);
    }
    console.log(
      "checkAttemptedQuestionRes['remainingTime'] =>",
      checkAttemptedQuestionRes['remainingTime']
    );

    setIsLoading(false);
  };
  console.log(attemptedQuestionMap, 'index');
  return (
    <div>
      {isLoading ? (
        <div className='fullscreen'>
          <Loading />
        </div>
      ) : (
        <div style={{ color: 'black' }}>
          {/* <div className="row m-1 pr-3">
                        <div className="col-lg-4 col-9 mt-0">
                            <div>
                                <small style={{ fontWeight: '500' }}>
                                    <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                    <span className="ml-lg-2 ml-2">
                                        Answer Key Document
                                    </span>
                                </small>
                            </div>
                        </div>
                        <div className="col-lg-8 text-lg-left col-3 text-right">
                            <div className="icon">
                                <button
                                    className="ml-lg-5 font-weight-bold bg-transparent small border-0"
                                    style={{ color: '#0555E2' }}
                                    onClick={() => {
                                        //   handleOpenModal('pdf')
                                    }}
                                >
                                    Open
                                </button>
                            </div>
                        </div>
                    </div> */}
          {questions.length > 0 && (
            <QuestionSelection
              // selectedOption={choosenOption}
              questionNumber={currentIndex + 1}
              showCorrectAns={true}
              selectedOptionByUser={
                attemptedQuestionMap.get(questions[currentIndex]['_id'])
                  ? attemptedQuestionMap.get(questions[currentIndex]['_id'])[
                      'selectedOptions'
                    ]
                  : ''
              }
              //   selectedOptionByUser={
              //     attemptedQuestionMap.get(questions[currentIndex]['_id'])
              //       ? attemptedQuestionMap.get(questions[currentIndex]['_id'])[
              //           'selectedOptions'
              //         ]
              //       : ''
              //   }
              isSelectable={false}
              question={questions[currentIndex]}
            />
          )}
          {/* <div clas="m-2 p-2">Ans:</div> */}

          {/* <div
                  className="ml-2 mr-2 mt-3 mx-0 mx-lg-2 pb-4 pt-4 pt-lg-5 row justify-content-center"
                  style={{ background: '#e4e4e4', borderRadius: '20px' }}
                >Loading</div> */}
          <Row className='pt-lg-4 mx-0 mx-lg-2 pb-lg-4 justify-content-center'>
            <div className='col-lg-2 col-4 mt-3 mt-lg-0 mt-md-0'>
              <div>
                <button
                  type='button'
                  className='btn btn-info border-0 ml-lg-0 text-nowrap font-size-14px btn-block'
                  onClick={() =>
                    currentIndex !== 0 && setCurrentIndex(currentIndex - 1)
                  }
                  style={{ background: 'white', color: '#ff0053' }}
                >
                  <i className='fa fa-chevron-left' aria-hidden='true'></i>
                  <span>Previous</span>
                </button>
              </div>
            </div>
            <div className='col-lg-2 col-4 mt-3 mt-lg-0 mt-md-0'>
              <div>
                <button
                  type='button'
                  className='btn btn-info border-0 ml-lg-0 text-nowrap font-size-14px btn-block'
                  // onClick={this.handleClear}
                  style={{ background: '#e4e4e4', color: 'black' }}
                >
                  <span>Q.No . </span>
                  {currentIndex + 1}
                </button>
              </div>
            </div>
            <div className='col-lg-2 col-4 mt-3 mt-lg-0 mt-md-0'>
              {questions.length !== currentIndex + 1 ? (
                <div>
                  <button
                    type='button'
                    className='btn btn-info border-0 ml-lg-0 text-nowrap font-size-14px btn-block'
                    onClick={() =>
                      currentIndex !== questions[questions.length - 1] &&
                      setCurrentIndex(currentIndex + 1)
                    }
                    style={{ background: 'white', color: '#ff0053' }}
                  >
                    <span>Next</span>
                    <i
                      className='fa fa-chevron-right'
                      style={{ paddingLeft: '8px' }}
                      aria-hidden='true'
                    ></i>
                  </button>
                </div>
              ) : null}
            </div>
          </Row>

          <div className='row align-items-center m-1 p-3'>
            {testReview.map((a, idx) => {
              return (
                <div
                  key={idx}
                  className='row col-lg-3 col-xs-6 col-6 gx-5 m-0 p-1'
                >
                  <div style={a['style']}></div>
                  <div className='pl-2'>{a['status']}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default AnswerReview;
