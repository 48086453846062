import { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from 'components/Loading';
import { bijBolPost } from 'services/api';

const Scorecard = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [scoreDetails, setScoreDetails] = useState({
    totalQuestions: 0,
    correctAnswers: 0,
    attemptedQuestions: 0,
    unattemptedQuestions: 0,
    wrongAnswers: 0,
    score: 0,
    totalScore: 0,
    testObj: {},
    testName: '',
    testId: '',
  });

  useEffect(() => {
    console.log(props.match.params);

    const testReq = { testId: props.match.params.testId, onlyInfo: false };

    bijBolPost('getEntranceTestById', testReq)
      .then((resultTest) => {
        var datas = {
          entranceCourseId: props.match.params.courseId,
          testId: props.match.params.testId,
          topicTestFlag: resultTest.data.data['showAnswer'],
        };
        console.log('datas', datas);

        bijBolPost('getTestResults', datas)
          .then((res) => {
            console.log('res>>', res.data);
            var cal_obj = res.data.data;
            setScoreDetails({
              totalQuestions: cal_obj.totalQuestions,
              correctAnswers: cal_obj.correctAnswers,
              attemptedQuestions: cal_obj.attemptedQuestions,
              unattemptedQuestions: cal_obj.unattemptedQuestions,
              wrongAnswers: cal_obj.wrongAnswers,
              score: cal_obj.score,
              totalScore: cal_obj.totalScore,
              testObj: {},
              testName: cal_obj.testName,
              testId: cal_obj.testId,
            });
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <Container fluid>
        <Row className='mx-lg-3 mt-lg-5 mb-lg-5 mt-5 mb-4'>
          <div className='col-lg-12 col-12'>
            <div className='card border-0 shadow '>
              <div className='bg-dark p-lg-4 card-border-radius p-4'>
                <Row className='align-items-center'>
                  <div className='col-lg-9 col-8'>
                    <p className='text-white'>{scoreDetails.testName}</p>
                  </div>
                </Row>
              </div>
            </div>
            <div className='bg-white shadow card-bottom-border-radius pb-lg-4 pb-4'>
              <Row className='pt-lg-4 mx-0 mx-lg-2 pt-4'>
                <div className='col-12 text-center'>
                  <h5 className='font-weight-bold' style={{ color: '#0555E2' }}>
                    Congratulations!
                  </h5>
                  <small>You have successfully completed Mock Test</small>
                </div>
              </Row>
              {isLoading ? (
                <Row
                  className='ml-2 mr-2 mt-3 mx-0 mx-lg-2 pb-4 pt-4 pt-lg-5 row justify-content-center'
                  style={{ background: '#e4e4e4', borderRadius: '20px' }}
                >
                  <Loading />
                </Row>
              ) : (
                <Row
                  className='ml-2 mr-2 mt-3 mx-0 mx-lg-2 pb-4 pt-4 pt-lg-5 row'
                  style={{ background: '#e4e4e4', borderRadius: '20px' }}
                >
                  <div className='col-lg-4 text-center col-6'>
                    <div>
                      <h3
                        className='font-weight-bold'
                        style={{ color: '#0555E2' }}
                      >
                        {scoreDetails.attemptedQuestions}
                      </h3>
                    </div>
                    <div>
                      <small>Attempted Question</small>
                    </div>
                  </div>
                  <div className='col-lg-4 text-center col-6'>
                    <div>
                      <h3
                        className='font-weight-bold'
                        style={{ color: '#0555E2' }}
                      >
                        {scoreDetails.correctAnswers}
                      </h3>
                    </div>
                    <div>
                      <small>No. of Correct Answers</small>
                    </div>
                  </div>
                  <div className='col-lg-4 text-center col-6 mt-4 mt-lg-0 mt-md-0'>
                    <div>
                      <h3
                        className='font-weight-bold'
                        style={{ color: '#0555E2' }}
                      >
                        {scoreDetails.wrongAnswers}
                      </h3>
                    </div>
                    <div>
                      <small>No. of Incorrect Answers</small>
                    </div>
                  </div>
                  <div className='col-lg-4 text-center col-6 mt-4 mt-lg-3 mt-md-0'>
                    <div>
                      <h3
                        className='font-weight-bold'
                        style={{ color: '#0555E2' }}
                      >
                        {scoreDetails.unattemptedQuestions}
                      </h3>
                    </div>
                    <div>
                      <small>Un-Attempted Question</small>
                    </div>
                  </div>
                  <div className='col-lg-4 text-center col-6 mt-4 mt-lg-3 mt-md-0'>
                    <div>
                      <h3
                        className='font-weight-bold'
                        style={{ color: '#0555E2' }}
                      >
                        {scoreDetails.score}/{scoreDetails.totalScore}
                      </h3>
                    </div>
                    <div>
                      <small>Marks Scored</small>
                    </div>
                  </div>
                  <div className='col-lg-4 text-center col-6 mt-4 mt-lg-3 mt-md-0'>
                    <div>
                      <h3
                        className='font-weight-bold'
                        style={{ color: '#0555E2' }}
                      >
                        {scoreDetails.totalQuestions}
                      </h3>
                    </div>
                    <div>
                      <small>Total Questions</small>
                    </div>
                  </div>
                </Row>
              )}
              <div className='mx-0 mt-lg-4 mt-4 row'>
                <div className='col-lg-6 col-6 text-lg-right text-right pr-lg-0 pr-0'>
                  <Link
                    to={{
                      pathname: `/Answerskey/${scoreDetails.testId}`,
                      state: { data: scoreDetails },
                    }}
                  >
                    <button
                      type='button'
                      className='btn btn-danger pl-4 pr-4 border-0'
                      style={{ background: '#0555E2' }}
                    >
                      Answer Key
                    </button>
                  </Link>
                </div>
                <div className='col-lg-6 col-6 text-lg-left text-left pl-lg-0 pl-0'>
                  <Link to='/home'>
                    <button
                      type='button'
                      className='btn btn-danger pl-5 pr-5 ml-3 border-0'
                      style={{ background: '#75757e', color: '#fff' }}
                    >
                      Exit
                    </button>
                  </Link>
                </div>
              </div>
              {/* <div className="mx-0 mt-lg-4 mt-5 d-flex ml-3">
                  <div>
                 
                    <Link to={`/Answerskey/${testId}`}><button type="button" className="btn btn-danger pl-4 pr-4 border-0" style={{background: '#0555E2'}}>
                      Answer Key
                    </button></Link>
                  </div>
                  <div>
                    <Link to="/home"><button type="button" className="btn btn-danger pl-5 pr-5 ml-3 border-0" style={{background: '#75757e', color: '#fff'}}>
                      Exit
                    </button></Link>
                  </div>
                </div> */}
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Scorecard;
