import { useDispatch } from 'react-redux';
import React, { useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from 'assets/images/setmlablogo.png';
import Mobilemenu from 'components/Mobilemenu';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { profileImgHost } from 'assets/data/constants';
import { useLogout } from '../hooks/auth';
// import { useSetExt } from '../hooks/query'

const Header = () => {
  const dispatch = useDispatch();
  // const updateInput = useRef(null);
  const logout = useLogout();
  const updateMobileInput = useRef(null);
  const [searchInp, setSearchInp] = useState();
  const userSelector = useSelector((state) => state['Profile']);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const isExternal = useSelector((state) => state['QueryList']['ext']);
  
  console.log('isExternal => ', isExternal);

  console.warn(location['pathname']);

  let pageName = location['pathname'];

  if (pageName == '/home') {
    console.warn('Yes');
    //setSearchInp('')
  }
  const onMenuOpen = (event) => {
    //setAnchorEl('Link' + event.currentTarget);

    console.warn(event.currentTarget);
  };
  const onMenuClose = () => {
    setAnchorEl(null);
  };
  console.log('userSelector header=>', userSelector);
  let history = useHistory();

  console.warn(history);

  // const onSearch = (e) => {
  //   if (searchInp) {
  //     e.preventDefault();
  //     console.log(searchInp);
  //     dispatch({ type: 'SEARCH', value: searchInp });
  //     setSearchInp('');
  //     history.push('/search');
  //   } else {
  //     alert('Enter Topic TO Search');
  //   }
  // };

  const onSearchMobile = (e) => {
    if (searchInp) {
      e.preventDefault();
      console.log(searchInp);

      dispatch({ type: 'SEARCH', value: searchInp });
      setSearchInp('');
      history.push('/search');
    } else {
      alert('Enter Topic TO Search');
    }
  };

  const profileMenuRender = () => {
    return (
      <div className='justify-content-center'>
        <Avatar
           alt= {userSelector['userName']}
          src={profileImgHost + userSelector['profileImage']}
        />
        <span
          className='text-center'
          aria-controls={openMenu ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={openMenu ? 'true' : undefined}
          onClick={onMenuOpen}
        >
          {userSelector['userName']}
        </span>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={openMenu}
          onClose={onMenuClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={logout}>
            <div>Logout</div>
          </MenuItem>
        </Menu>
      </div>
    );
  };

  return (
    <div>
      <Container fluid>
        <Row className='align-items-center'>

          {/* <div className='col-lg-3 order-1 order-lg-0 order-md-0 d-none d-lg-block d-md-none'> */}
          {/* <span className='btn-show-pass pl-3 pr-3' onClick={onSearch}>
              <i aria-hidden='true' className='fa fa-search'></i>
            </span> */}
          {/* <input
              type='text'
              placeholder='Search . . .'
              className='border_radius_10px form-control form_control border'
              ref={updateInput}
              value={searchInp}
              onChange={(e) => setSearchInp(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setSearchInp(e.target.value);
                }
              }}
            ></input> */}


          {/* </div> */}

          {/* <div className='col-lg-2 text-center d-none d-lg-block d-md-none'>
            <a href className='font-weight-bold text-dark text-decoration-none'>
              +91 8985042784
            </a>
          </div> */}

          {/* <div className='col-lg-2 text-center d-none d-lg-block d-md-none'>
            <div className='d-flex ml-lg-5'>
              <div>
                {/* <a href>
                  <i
                    className='fa fa-facebook-square fa-2x'
                    aria-hidden='true'
                  ></i>
                </a> */}
          {/* </div> */}
          {/* <div> */}
          {/* <a href>
                  <i
                    className='fa fa-twitter-square fa-2x ml-lg-3'
                    aria-hidden='true'
                  ></i>
                </a> */}
          {/* </div> */}
          {/* <div> */}
          {/* <a href>
                  <i
                    className='fa fa-instagram fa-2x ml-lg-3'
                    aria-hidden='true'
                  ></i>
                </a> */}

          {/* </div> */}
          {/* </div> */}
          {/* </div> */}

          <div className='col-lg-12 text-center d-none d-lg-block d-md-none'>


          <Navbar
                className='shadow is-fixed navbar navbar-light navbar-nav justify-content-between'
                collapseOnSelect
                expand='lg'
              >
                {/* <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                <Navbar.Collapse id='responsive-navbar-nav'>
                  <Nav> */}
                <div className='d-flex'>
                  <img src={logo} alt='logo' />
                  <Link to='/home'>
                    <li className='nav-item pl-lg-3 pr-lg-3 '>
                      <a href className='nav-link text-decoration-none text-dark'>
                        Home
                      </a>
                    </li>
                  </Link>
                  {/* <Link to='/about'>
                        <li className='nav-item pl-lg-3 pr-lg-3 '>
                          <a href className='nav-link text-decoration-none text-dark'>
                            Bijbol Education
                          </a>
                        </li>
                      </Link> */}
                  {/* <li className="nav-item pl-lg-3 pr-lg-3 ">
                                    <a href="https://www.bijbol.com/?page_id=1155" target="_blank" rel="noreferrer" className="nav-link text-dark">Case Studies </a>
                                </li>
                                <li className="nav-item pl-lg-3 pr-lg-3 ">
                                    <a href="https://www.bijbol.com/?page_id=1189" target="_blank" rel="noreferrer" className="nav-link text-dark">
                                      Jobs
                                      </a>
                                </li>
                                <li className="nav-item pl-lg-3 pr-lg-3 ">
                                    <a href="https://www.bijbol.com/?page_id=1191" target="_blank" rel="noreferrer" className="nav-link text-dark">
                                      Consultants
                                      </a>
                                </li>
                                <li className="nav-item pl-lg-3 pr-lg-3 ">
                                    <a href="https://www.bijbol.com/?page_id=1193" target="_blank" rel="noreferrer" className="nav-link text-dark">
                                      Business Network
                                      
                                      </a>
                                </li>
                                <li className="nav-item pl-lg-3 pr-lg-3 ">
                                    <a href="https://www.bijbol.com/?page_id=1197" target="_blank" rel="noreferrer" className="nav-link text-dark">
                                      Events
                                      
                                      </a>
                                </li>
                                <li className="nav-item pl-lg-3 pr-lg-3 ">
                                    <a href="https://www.bijbol.com/?page_id=1199" target="_blank" rel="noreferrer" className="nav-link text-dark">
                                      
                                      Dental Labs 
                                      </a>
                                </li>
                               <li className='nav-item pl-lg-3 pr-lg-3 '>
                  <a href className='nav-link text-dark'>
                    Market Place
                  </a>
                </li> */}
                  <Link to='/Subscription'>
                    <li className='nav-item pl-lg-3 pr-lg-3 '>
                      <a href className='nav-link text-dark'>
                        Subscription
                      </a>
                    </li>
                  </Link>
                  <Link to='/PaymentHistory'>
                    <li className='nav-item pl-lg-3 pr-lg-3'>
                      <a href className='nav-link text-dark'>
                        Payment History
                      </a>
                    </li>
                  </Link>
                  {!isExternal && userSelector && userSelector['profileImage'] && (
                    <li className='nav-item'>
                      <a href className='nav-link text-dark' onClick={logout}>
                        Logout
                      </a>
                    </li>
                  )}
                </div>


                <div className=''>
                  {userSelector && userSelector['profileImage'] ? (
                    profileMenuRender()
                  ) : (


                    <><Link to='/Login'>
                      <a
                        
                        className='font-weight-bold text-dark text-decoration-none'
                      >
                        LOGIN/
                      </a>
                    </Link>
                    <Link to='/signup'>
                        <a
                          
                          className='font-weight-bold text-dark text-decoration-none'
                        >
                          REGISTER
                        </a>
                      </Link></>


                    


                  )}
                </div>
                {/* </Nav>
                </Navbar.Collapse> */}
              </Navbar>

          </div>
          {/* <div className='col-6 col-lg-2 order-0 pb-3 pb-lg-0 pb-md-0 pt-3 pt-lg-0 pt-md-0 text-right order-lg-1 order-md-1 d-none d-lg-block d-md-block'> */}



          {/* <div class="dropdown">
              <button
                class="dropbtn"
                className="text-dark border-0 bg-transparent"
              >
                Welcome User
              </button>

              <div
                class="dropdown-content text-left mt-2 "
                style={{ border: "0.5px solid #0555E2" }}
              >
                <Link to="/ProfileDashboard" className="text-decoration-none">
                  <a className="text-decoration-none">Profile Dashboard</a>
                </Link>

                <Link to="/Login" className="text-decoration-none">
                  <a className="text-decoration-none">Logout</a>
                </Link>

              </div>
            </div> */}
          {/* </div> */}
        </Row>
      </Container>

      <Container fluid className='d-block d-md-none d-lg-none'>
        {/* Mobile Menu */}
        <div>
          <Mobilemenu
            loggedIn={userSelector && userSelector['profileImage']}
          ></Mobilemenu>
        </div>
        {/* Mobile Menu End*/}

        {/* Profile Menu */}
        {userSelector && userSelector['profileImage'] && !isExternal && (
          <div className='mt-3 row justify-content-between'>
            <h4 className='text-center'>Hi, {userSelector['userName']}</h4>
            <Avatar
              alt='Profile'
              src={profileImgHost + userSelector['profileImage']}
            />
          </div>
        )}
        {/* Profile Menu End*/}

        <Row className='mt-4'>
          <div className='col-12'>
            <span className='btn-show-pass pl-3 pr-3' onClick={onSearchMobile}>
              <i aria-hidden='true' className='fa fa-search'></i>
            </span>
            <input
              type='text'
              placeholder='Search . . .'
              className='border_radius_10px form-control form_control border'
              ref={updateMobileInput}
              value={searchInp}
              onChange={(e) => setSearchInp(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setSearchInp(e.target.value);
                }
              }}
            ></input>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Header;
