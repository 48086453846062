const initialState = {
  users: null,
};

export default function SearchReducer(state = [], action) {
  switch (action.type) {
    case "SEARCH":
      return {
        initialState,
        searchInputValue: action.value,
      };
    default:
      return state
  }
}